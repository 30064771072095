import { compose } from "redux"

import WithHOC from "./actions"
import CoreRenderFunction from "DisplayCore"
import { OrderBackdateLayout } from "PageLayout/OrderManagement/OrderBackdate"
import { useEffect } from "react"
import { Form } from "antd"
import _ from "lodash"


const OrderBackdate = (props) => {
  const {
    can_read,
    can_create,
    can_update,
    can_delete
  } = props.modulePermissions

  const {
    query,
    onChangeHOC,
    exportToCSV,
    getBackdates,
    getBackdateDetail,
    selectedBackdate,
    getCUOs,
    submitBackdate,
    approvalBackdate,
    action
  } = props

  const [filterForm] = Form.useForm()
  const [detailForm] = Form.useForm()

  useEffect(() => {
    getCUOs()
  }, [getCUOs])

  useEffect(() => {
    getBackdates()
  }, [query, getBackdates])

  useEffect(() => {
    if(!action) {
      detailForm.resetFields()
      onChangeHOC('selectedCUOs', { keys: [], rows: [] })
      onChangeHOC('uploadedDocument', [])
      onChangeHOC('uploadedDocumentInfo', {})
      onChangeHOC('selectedBackdate', {})
    }
  }, [action])

  useEffect(() => {
    detailForm.setFieldsValue(selectedBackdate)
  }, [selectedBackdate])

  const onClickAction = (actionKey, ...args) => {
    switch(actionKey) {
      case 'CLEAR_FILTER':
        onChangeHOC('query', { filter: {}, sorter: {} })
        filterForm.resetFields()
        break
      case 'EXPORT_TO_CSV':
        exportToCSV()
        break
      case 'OPEN_CREATE_MODAL':
        onChangeHOC('action', 'create')
        break
      case 'CLOSE_DETAIL_MODAL':
        onChangeHOC('action', null)
        break
      case 'OPEN_CUO_OPTIONS':
        onChangeHOC('showCUOOptionsModal', true)
        break
      case 'CLOSE_CUO_OPTIONS':
        onChangeHOC('showCUOOptionsModal', false)
        break
      case 'ON_SUBMIT_CUO_OPTIONS':
        onChangeHOC("selectedCUOs", args[0])
        onChangeHOC("showCUOOptionsModal", false)
        break
      case 'UPLOAD_DOCUMENT':
        if(args[0] && args[0].length > 0) {
          const reader = new FileReader()
          reader.onload = () => {
            onChangeHOC('uploadedDocumentInfo', {
              base64: reader.result,
              file_name: args[0][0].filename,
              file_type: args[0][0].fileType,
              isExisting: false
            })
          }
          reader.readAsDataURL(args[0][0].file)
          onChangeHOC('uploadedDocument', [])
        }
        break
      case 'REMOVE_UPLOADED_DOCUMENT':
        onChangeHOC('uploadedDocumentInfo', {})
        break
      case 'VIEW':
        getBackdateDetail(args[0].id, 'view')
        break
      case 'UPDATE':
        getBackdateDetail(args[0].id, 'update')
        break
      case 'APPROVE_BACKDATE':
        approvalBackdate('approve')
        break
      case 'REJECT_BACKDATE':
        approvalBackdate('reject')
        break
      default: return
    }
  }

  const hideComponent = ( actionKey ) => {
    switch( actionKey ){
      case 'noCreatePermission':
        return !can_create
      case 'noUpdatePermission':
        return !can_update
      case 'noDeletePermission':
        return !can_delete
      case 'noDocumentUploaded':
        return _.isEmpty(props.uploadedDocumentInfo)
      case 'hideCreate':
        return action === 'create'
      case 'hideUpdate':
        return action === 'update'
      case 'hideView':
        return action === 'view'
      case 'finalized':
        return selectedBackdate?.status === 'approved' || selectedBackdate?.status === 'rejected'
      default:
    }
  }

  const disabledComponent = ( actionKey ) => {
    switch( actionKey ){
      case 'disabledView':
        return action === 'view'
      default:
    }
  }

  const onSubmitDetailForm = (formData) => {
    const data = {
      document: {
        number: formData.document_no,
        name: props.uploadedDocumentInfo.file_name,
        base64: props.uploadedDocumentInfo.base64,
        isExisting: props.uploadedDocumentInfo.isExisting
      },
      client_user_orders: props.selectedCUOs.keys
    }

    submitBackdate(data)
  }

  return (
    <>
      {
        can_read && (
          <CoreRenderFunction
            {...props}
            item={OrderBackdateLayout.content}
            filterForm={filterForm}
            detailForm={detailForm}
            onSubmitDetailForm={onSubmitDetailForm}
            onClickAction={onClickAction}
            hideComponent={hideComponent}
            disabledComponent={disabledComponent}
            isCreate={action === 'create'}
            isView={action === 'view' && !_.isEmpty(selectedBackdate)}
            isUpdate={action === 'update' && !_.isEmpty(selectedBackdate)}
          />
        )
      }
    </>
  )
}

export default compose(WithHOC)(OrderBackdate)