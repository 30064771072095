export const en = {
  translation: {
    // Language
    "ENGLISH": "English",
    "MANDARIN": "Mandarin",
    "MALAY": "Malay",

    // general
    "ACTIONS": "Actions",
    "ACTIVE": "Active",
    "ADD": "Add",
    "ADDED_TO_CART": "Item added into cart",
    "ADJUST": "Adjust",
    "ALL": "All",
    "AMOUNT": "Amount",
    "CURRENCY": "Currency",
    "ARE_YOU_SURE": "Are you sure?",
    "CANCEL": "Cancel",
    "CAN_CREATE": "Can Create",
    "CAN_DELETE": "Can Delete",
    "CAN_READ": "Can Read",
    "CAN_UPDATE": "Can Update",
    "CLEAR": "Clear",
    "COLLAPSE": "Collapse",
    "DATE": "Date",
    "DELETE": "Delete",
    "DUPLICATE": "Duplicate",
    "EDIT": "Edit",
    "EXPAND": "Expand",
    "FALSE": "False",
    "FILTER": "Filter",
    "FILTER_BY": "Filter By",
    "IMAGE": "Image",
    "IMAGES": "Images",
    "MOBILE_IMAGE": "Mobile Image",
    "INACTIVE": "Inactive",
    "INDEX_NO": "No",
    "COMBINATION": "Combination",
    "INVALID_COMBINATION": "Invalid Combination",
    "MONTH": "Month",
    "PLEASE_PICK_A_MONTH": "Please Pick a Month",
    "NAME": "Name",
    "NO": "No",
    "NONE": "None",
    "PAGE": "Page",
    "ENTER": "Enter",
    "PLEASE_ENTER": "Please enter",
    "PLEASE_ENTER_THE": "Please enter the",
    "PLEASE_ENTER_YOUR": "Please enter your",
    "PLEASE_SELECT": "Please Select",
    "SELECT": "Select ",
    "SELECT_ALL": "Select All",
    "PLEASE_SELECT_A": "Please select a",
    "PLEASE_SELECT_AN": "Please select an",
    "PLEASE_SELECT_YOUR": "Please Select your",
    "SELECT_YOUR": "Select your",
    "SELECT_A": "Select a",
    "SELECT_AN": "Select an",
    "REMARKS": "Remarks",
    "REMOVED_FROM_CART": "Item removed from cart",
    "RESET": "Reset",
    "SEARCH": "Search",
    "SESSION_EXPIRED_PLEASE_RELOGIN": "Session is expired please re-login",
    "SHOW": "Show",
    "STATUS": "Status",
    "STATUS_UPDATE_SUCCESSFULLY": "Status Updated successfully",
    "SUBMIT": "Submit",
    "SUBTOTAL": "Subtotal",
    "SUBTYPE": "Subtype",
    "SUBTOTAL_AMOUNT": "Subtotal Amount",
    "TERMS_AND_CONDITIONS": "Terms and Conditions",
    "TOTAL": "Total",
    "TRUE": "True",
    "UPDATE": "Update",
    "UPDATE_SUCCESSFUL": "Update Successful",
    "VIEW": "View",
    "WELCOME": "Welcome",
    "YES": "Yes",
    "ACCEPT": "Accept",
    "REJECT": "Reject",
    "REJECT_ALL": "Reject",
    "EXTRA": "Extra",
    "SAVE": "Save",
    "NO_DATA_FOUND": "No Data Found",
    "REMOVE": "Remove",
    "APPLY": "Apply",
    "DETAILS": "Details",
    "VIEW_CAPITALIZE": "VIEW",
    "DOWNLOAD": "Download",
    "YEAR": "Year",
    "ONLY": "Only",
    "CAMPAIGN": "Campaign",
    "REPLACEMENT": "Replacement",
    "SAMPLE": "Sample",
    "REDEMPTION": "Redemption",
    "EVENT": "Event",
    "MCP": "MCP",
    "PC_ONBOARDING_GIFT": "PC Onboarding Gift",
    "CLAIMS_TO_WAREHOUSE/FOWDER": "Claims To Warehouse/Fowder",

    // Menu
    "ANNOUNCEMENT": "Announcement",
    "ANNOUNCEMENT_LIST": "Announcement List",
    "BANNER_LIST": "Banner List",
    "CHECKOUT_CODES": "Checkout Codes",
    "CLIENT_CONFIGUTATIONS": "Client Configuratons",
    "COMMISSIONS_REPORT": "Commissions Report",
    "COMMISSION_DETAIL_REPORT": "Commissions Detail Report",
    "COMMISSION_SUMMARY_REPORT": "Commissions Summary Report",
    "CONTACT_US": "Contact Us",
    "CONVERT": "Convert",
    "DASHBOARD": "Dashboard",
    "DISPENSE_LIST": "Dispense List",
    "DISPLAY_SEQUENCE": "Display Sequence",
    "INVENTORY": "Inventory",
    "INVENTORY_REPORT": "Inventory Report",
    "INVENTORY_PERPETUAL_LIST": "Inventory Perpetual List",
    "MALL": "Mall",
    "MEMBER_LIST": "Member List",
    "MEMBER_SALES_AND_FULFILMENT": "Members Sales and Fulfilment",
    "ORDER_MANAGEMENT": "Order Management",
    "ORDERS": "Orders",
    "ORDER_BACKDATE": "Order Backdate",
    "ORGANISATION_CHART": "Organisation Chart",
    "PACKAGES": "Packages",
    "PACKAGE": "Package",
    "PLACEMENT_TREE": "Placement Tree",
    "PRODUCTS": "Products",
    "PRODUCT": "Product",
    "PRODUCTS_PER_GROUP": "Products Per Group",
    "PRODUCT_LIST": "Product List",
    "STOCK_LIST": "PC Stock Masterlist",
    "PRODUCT_PACKAGES": "Product Packages",
    "PRODUCTS_CATEGORY": "Products Category",
    "PRODUCTS_TAGS": "Products Tags",
    "PRODUCTS_MANAGEMENT": "Products Management",
    "PROFILE": "Profile",
    "REDEMPTION_MALL": "Redemption Mall",
    "RELOAD": "Reload",
    "REPORTS": "Reports",
    "ROLE_PERMISSION": "Role Permission",
    "SALES_SUMMARY_REPORT": "Sales Summary Report",
    "SALES_REPORT": "Sales Report",
    "SALES_DETAIL_REPORT": "Sales Detail Report",
    "SELF_COLLECTION_LIST": "Self Collection List",
    "SHIPMENT": "Shipment",
    "SHIPMENT_ZONE_AND_PRICINGS": "Shipment Zone and Pricings",
    "SHIPMENT_FEE": "Shipment Fee",
    "SHIPPING_LIST": "Shipping List",
    "SHOPPING_MALL": "Shopping Mall",
    "SPONSOR_TREE": "Sponsor Tree",
    "STAFF_SETTINGS": "Staff Settings",
    "STOCK_ADJUSTMENT_LIST": "Stock Adjustment List",
    "STOCK_ADJUSTMENT_REPORT": "Stock Adjustment Report",
    "SUBADMIN_LIST": "Subadmin List",
    "SYSTEM_SETTINGS": "System Settings",
    "TRANSACTION_APPROVAL": "Transactions Approval",
    "TRANSFER": "Transfer",
    "USERS": "Users",
    "VOUCHER_MANAGEMENT": "Voucher Management",
    "WALLETS": "Wallet",
    "WALLET_STATEMENT": "Wallet Statement",
    "WAREHOUSES": "Warehouses",
    "WAREHOUSES_MANAGEMENT": "Warehouses Management",
    "WITHDRAWAL": "Withdrawal",
    "UPGRADE_MALL": "Upgrade Mall",
    "DOWNLOAD_CP58": "Download CP58",
    "BULK_DOWNLOAD_CP58": "Bulk Download CP58",
    "MEMBER_OFFLINE_REWARD": "Member Offline Reward",

    //Company
    "COMPANY_INFORMATION": "Company Information",
    "DIRECTOR_INFORMATION": "Director's Information",
    "REGISTRATION_NUMBER": "Registration Number",
    "DESIGNATION": "Designation",

    //Offline Reward
    "OFFLINE_REWARD": "Offline Reward",
    "ADD_REWARDS": "Add Rewards",
    "REWARD_NAME": "Reward Name",
    "REWARD_AMOUNT": "Reward Amount",
    "REWARD_DATE_TIME": "Reward Date",
    "REWARD_IS_CREATED": "Reward is created",
    "REWARD_IS_UPDATED": "Reward is updated",

    // Header
    "ACCOUNT": "Account",
    "ALL_UPGRADE_REACHED": "All upgrade has been reached",
    "BACK_TO_ADMIN_ACCOUNT": "Back To Admin Account",
    "CONFIRM": "Confirm",
    "CURRENT_UPGRADE": "Current Upgrade",
    "EMPTY_CART_MESSAGE": "The cart is empty.",
    "LOGOUT": "Log Out",
    "MALL_CART": "Mall Cart",
    "NEXT_UPGRADE": "Next Upgrade",
    "NOTIFICATIONS": "NOTIFICATIONS",
    "PROCEED_TO_CHECKOUT": "Proceed To Checkout",
    "QUANTITY": "Quantity",
    "ROLE": "Role",
    "SUB_TOTAL": "Sub-Total",
    "SWITCH_LANGUAGE": "Switch Language",
    "UPDATE_PROFILE": "Update Profile",
    "UPGRADE_CART": "Upgrade Cart",
    "UPGRADE_NOW": "Upgrade Now",
    "VIEW_ALL_NOTIFICATIONS": "View All Notifications",
    "VIEW_DETAIL": "View Detail",

    // Dashboard
    "NO_ANNOUNCEMENT": "No announcement at the moment",
    "LATEST_ANNOUNCEMENTS": "Latest Announcements",
    "SEE_MORE": "See More",
    "TITLE": "Title",
    "DIRECT_RECRUITS": "Direct Recruits",
    "LEADER": "Leader",
    "MONTHLY_RETAIL_BONUS": "Monthly Retail Bonus",
    "PAST_MONTH": "Past Month",
    "POOL_BONUS": "Pool Bonus",
    "TOTAL_TEAM_MEMBERS": "Total Team Members",
    "AWAITING_PAYMENT": "Awaiting Payment",
    "ORDER_STATUS": "Order Status",
    "PAST_90_DAYS": "Past 90 Days",
    "PREPARING_SHIPMENT": "Preparing Shipment",
    "INTRANSIT": "Intransit",
    "SALES_PERFORMANCE": "Sales Performance",
    "EXCLUDE_SHIPPING_FEE": "Ex Shipping Fee",

    // Performance Dashboard (Member)
    "DAILY_SALES_MYR": "Daily Sales MYR",
    "DAILY_SALES_SV": "Daily Sales SV",
    "DIRECT_RECRUIT_SV_MONTHLY": "Direct Recruit SV (Monthly)",
    "DIRECT_RECRUIT_SV_YEARLY": "Direct Recruit SV (Yearly)",
    "GROUP_SV": "Group SV",
    "PAIRING_BONUS_SV_CURRENT_MONTH": "Pairing Bonus SV (Current Month)",
    "PAIRING_BONUS_SV_YESTERDAY": "Pairing Bonus SV (Yesterday)",
    "PERFORMANCE_DASHBOARD": "Performance Dashboard",
    "PERSONAL_REPURCHASE_SV_MONTHLY": "Personal Repurchase SV (Monthly)",
    "PERSONAL_REPURCHASE_SV_YEARLY": "Personal Repurchase SV (Yearly)",
    "QUALIFIED_GROUP_SV": "Qualified Group SV",
    "TOTAL_COMMISSION_LAST_MONTH": "Total Commission (Last Month)",
    "TOTAL_GROUP_SV_MONTHLY": "Total Group SV (Monthly)",
    "TOTAL_GROUP_SV_YEARLY": "Total Group SV (Yearly)",
    "MEMBER_SINCE": "Member Since",

    // Performance Dashboard (Admin)
    "TOTAL_SALES_PREVIOUS_MONTH": "Total Sales (MYR) Of Previous Month",
    "TOTAL_SALES_CURRENT_MONTH": "Total Sales (MYR) Of Current Month",
    "TOTAL_SALES_WHOLE_YEAR": "Total Sales (MYR) of Whole Year",
    "TOTAL_SALES_SV_PREVIOUS_MONTH": "Total Sales (SV) Of Previous Month",
    "TOTAL_SALES_SV_CURRENT_MONTH": "Total Sales (SV) Of Current Month",
    "TOTAL_SALES_SV_WHOLE_YEAR": "Total Sales (SV) Of Whole Year",

    // Team Dashboard
    "BALANCE_SV": "Balance SV",
    "GROUP": "Group",
    "MY_GROUP": "My Group",
    "NEW": "New",
    "REAL_TIME_PLACEMENT_SV_BALANCE": "Real time placement SV balance",
    "REMAINING_SV": "Remaining SV",
    "TEAM_DASHBOARD": "Team Dashboard",
    "TOTAL_BONUS_PAYOUT_SV": "Total Bonus Payout SV",
    "TOTAL_COMMISSION": "Total Commission",
    "TOTAL_MYR": "Total MYR",

    // LBB Top 10
    "DAILY_SALES": "Daily Sales",
    "NEW_REGISTRATION": "New Registration",
    "REFERRAL_NAME": "Referral Name",
    "TOP_GROUP_SALES": "Top Group Sales",
    "TOP_RECRUITMENT": "Top Recruitment",
    "RECRUITMENTS": "Recruitments",
    "TOTAL_NO_OF_RECRUITS": "Total No. of Recruits",
    "TOTAL_NO_OF_SALES_GROUP": "Total No. of Sales(Group)",
    "TOTAL_NO_OF_SALES_INDIVIDUAL": "Total No. of Sales(Individual)",
    "UPGRADE_LIST": "Upgrade List",

    // Winna
    "CREDIT_WALLET": "Credit Wallet",
    "LEADER_RANKING": "Sales/Leader Ranking",
    "MEMBER_PROFILE": "Member Profile",
    "MONTHLY_SALES_TARGET": "Monthly Sales Target",
    "ORDER": "Order",
    "REPORT": "Report",
    "RESOURCES": "Resources",
    "REWARD_WALLET": "Reward Wallet",
    "SHOPPING": "Shopping",
    "TOP_LEADER": "Top Leader",
    "TOP_SALES": "Top Sales",

    // User
    "ADDRESS": "Address",
    "DATE_OF_BIRTH": "Date Of Birth",
    "DISPLAY_NAME": "Display Name",
    "EMAIL": "Email",
    "FEMALE": "Female",
    "FULL_NAME": "Full Name",
    "FIRST_NAME": "First Name",
    "LAST_NAME": "Last Name",
    "GENDER": "Gender",
    "INCOME_TAX_NO": "Income Tax No",
    "JOINED_DATE": "Joined Date",
    "KYC_APPROVAL_DATE": "KYC Approval Date",
    "KYC_SUBMISSION_DATE": "KYC Submission Date",
    "MAINTENCE_STATUS": "Maintenance Status",
    "MALE": "Male",
    "MEMBER_ID": "Member ID",
    "MOBILE": "Mobile",
    "MOBILE_NUMBER": "Mobile Number",
    "PLACEMENT_ID": "Placement ID",
    "PLACEMENT_ID_NAME": "Placement ID/Name",
    "PLACEMENT_NAME": "Placement Name",
    "RANK": "Rank",
    "RANKING": "Ranking",
    "REGISTRATION_DATE": "Registration Date",
    "SIGN_UP_DATE": "Signup Date",
    "SPONSOR_ID": "Sponsor ID",
    "SPONSOR_ID_NAME": "Sponsor ID/Name",
    "SPONSOR_NAME": "Sponsor Name",
    "TIER": "Tier",
    "USERNAME": "Username",
    "LOGIN_AS_MEMBER": "Login as Member",
    "CHANGE_PASSWORD": "Change Password",
    "CHANGE_SECURITY_PIN": "Change Security PIN",
    "SUSPEND_MEMBER": "Suspend Member",
    "UNSUSPEND_MEMBER": "Unsuspend Member",
    "TERMINATE_MEMBER": "Terminate Member",
    "VIEW_USER_DETAILS": "View User Details",
    "REFERRAL_DETAIL": "Referral Detail",
    "SPONSOR_DETAIL": "Sponsor Detail",
    "PLACEMENT_DETAIL": "Placement Detail",
    "DOB": "DOB",
    "REGISTRATION_TYPE_LONG": "Registration Type (IC/ passport/ SSM)",
    "PROFILE_PHOTO": "Profile Photo",
    "REGISTRATION_SUCCESS_MESSAGE_1": "You have registered an account with ${PLACEHOLDER} successfully",
    "REGISTRATION_SUCCESS_MESSAGE_2": "An email have sent to",
    "REGISTRATION_SUCCESS_MESSAGE_3": "Kindly check your email to complete the registration",

    // Document
    "BACK_IC": "Back IC",
    "DOCUMENT": "Document",
    "FRONT_IC_OR_IDENTITY_PHOTO": "Front IC/Passport/SSM Registration Cert",
    "IDENTITY_NUMBER": "IC. No./Passport No./SSM No.",
    'DOCUMENT_NUMBER': 'IC/Passport/SSM No.',

    // Address
    "ADDRESS_LINE_1": "Address Line 1",
    "ADDRESS_LINE_2": "Address Line 2",
    "CITY": "City / Province",
    "COUNTRY": "Country",
    "POSTCODE": "Zip / Postal Code",
    "POST_CODE": "Postal Code",
    "REGION": "Region",
    "STATE": "State",

    // Password
    "CONFIRM_PASSWORD": "Confirm Password",
    "CURRENT_PASSWORD": "Current Password",
    "NEW_PASSWORD": "New Password",
    "UPDATE_PASSWORD": "Update Password",

    // Security Pin
    "CONFIRM_SECURITY_PIN": "Confirm Security Pin",
    "CURRENT_SECURITY_PIN": "Current Security Pin",
    "NEW_SECURITY_PIN": "New Security Pin",
    "SECURITY_PIN": "Security Pin",
    "UPDATE_SECURITY_PIN": "Update Security Pin",
    "FORGOT_SECURITY_PIN": "Forgot Security Pin",
    "ENTER_EMAIL_TO_RESET_SECURITY_PIN": "Enter your email and we'll send you a link to reset security pin.",
    "RESET_SECURITY_PIN_EMAIL_HAS_BEEN_SENT": "The email to reset your security pin has been sent to your email. Please check your email.",
    "INVALID_TOKEN": "Invalid Token",
    "SECURITY_PIN_HAS_BEEN_RESET": "Security pin has been reset successfully",

    // Bank
    "BANK_ACCOUNT_NO": "Bank Account No",
    "BANK_DETAIL": "Bank Detail",
    "BANK_NAME": "Bank Name",
    "BASIC_INFO": "Basic Info",
    "HOLDER_NAME": "Holder Name",

    // Actions & Feedbacks
    "ARE_YOU_SURE_UPDATE": "Are you sure you want to update",
    "ARE_YOU_SURE_ACCEPT": "Are you sure you want to accept",
    "ARE_YOU_SURE_REJECT": "Are you sure you want to reject",
    "ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS": "Are you sure you want to delete this",
    "CHANGE_PASSWORD_FOR_MEMBER": "Change Password For Member",
    "CHANGE_SECURITY_PIN_FOR_MEMBER": "Change Security Pin For Member",
    "MEMBER_AGE_RESTRICTION": "Member must be 18 years old or above",
    "ONLY_NUMBERS": "Only Numbers",
    "ONLY_ALPHANUMERIC_VALUE_IS_ACCEPTABLE": "Only alphanumeric value is acceptable",
    "PASSWORD_DO_NOT_MATCH": "The two passwords that you entered do not match!",
    "SECURITY_PIN_CONFIRMATION_ERROR": "Security pin that you entered do not match",
    "CONFIRM_TO_ACCEPT_USER_APPLICATION": "Are you sure you want to accept this user's application?",
    "CONFIRM_TO_REJECT_USER_APPLICATION": "Are you sure you want to reject this user's application?",
    "CONFIRM_TO_TERMINATE_USER": "Are you sure you want to terminate this member?",
    "CONFIRM_TO_SUSPEND_USER": "Are you sure you want to suspend this member?",
    "CONFIRM_TO_UNSUSPEND_USER": "Are you sure you want to unsuspend this member?",
    "CONFIRM_TO_DELETE_RECIPIENT_ADDRESS": "Are you sure you want to delete this recipient address info?",

    // Mall
    "MALL_TYPE": "Mall Type",
    "WELCOME_TO_MALL": "Welcome to Mall",
    "FIFTY_PERCENT_OF": "50% of",
    "HUNDRED_PERCENT_OF": "100% of",
    "RELOAD_AND_UPGRADE": "Reload / Upgrade",
    "REACTIVATE": "Reactivate",
    "UPGRADE": "Upgrade",
    "ADD_TO_CART": "Add to Cart",
    "STOCK_REMAINING": "Stock Remaining",
    "SELECT_YOUR_PRODUCT": "Select Your Product",
    "SELECTED": "Selected",
    "MIX_AND_MATCH": "Mix And Match",

    // Table
    "MORE_INFO": "More Info",
    "NEXT": "Next",
    "NO_CONTENT_CREATED": "No Content Created",
    "PAGES": "Pages",
    "PREVIOUS": "Previous",
    "SEARCH_BY": "Search By",
    "VIEW_MORE": "View More",
    "NO_DATA_TO_DISPLAY": "No Data To Display",

    //Announcements
    "ADD_ANNOUNCEMENT": "Add Announcement",
    "ANNOUNCEMENT_IS_CREATED": "Announcement is created.",
    "ANNOUNCEMENT_IS_UPDATED": "Announcement is updated",
    "AN_ERROR_OCCURED_WHILE_CREATING_THE_ANNOUNCEMENT": "An error occured while creating the announcement.",
    "AN_ERROR_OCCURED_WHILE_UPDATING_THE_ANNOUNCEMENT": "An error occured while updating the announcement.",
    "DEFAULT": "Default",
    "DESCRIPTION": "Description",
    "DISPLAY_DATE": "Display Date",
    "EXPIRED_DATE": "Expired Date",
    "LANGUAGE": "Language",
    "LARGE_NUMBER_WILL_DISPLAY_FIRST": "Large number will display first",
    "PLEASE_ENTER_A_TITLE": "Please enter a title",
    "PLEASE_ENTER_THE_DISPLAY_DATE": "Please enter the display date",
    "PLEASE_ENTER_THE_EXPIRED_DATE": "Please enter the expired date",
    "PLEASE_SPECIFY_A_LANGUAGE": "Please specify a language",
    "PRIORITY_ORDER": "Priority Order",
    "POPUP": "Popup",
    "UPDATE_ANNOUNCEMENT": "Update Announcement",
    "UPLOAD_IMAGE": "Upload Image",
    "UPLOAD_MOBILE_IMAGE": "Upload Mobile Image",

    //Banner-List
    "ADD_BANNER": "Add Banner",
    "UPDATE_BANNER": "Update Banner",

    "CATEGORY": "Category",
    "TAGS": "Tags",
    "REGISTRATION_MALL": "Registration Mall",
    "SORT": "Sort",
    "FIRST": "First",
    "SECOND": "Second",
    "THIRD": "Third",

    "RECOMMENDED_SIZE": "Recommended size is 1440px x 560px",
    "RECOMMENDED_MOBILE_SIZE": "Recommended size is 1200px x 900px",
    "PLEASE_ENTER_SORT": "Please enter sort number",

    "LOAD_BANNER_LIST_ERROR": "An error occured",
    "ADD_BANNER_SUCCESS": "Banner is added",
    "ADD_BANNER_FAILED": "An error has occured while adding the banner",
    "UPDATE_BANNER_SUCCESS": "Banner is updated",
    "UPDATE_BANNER_FAILED": "An error has occured while updating the banner",

    "NORMAL_MALL": "Normal Mall",

    //Checkout
    "CHECKOUT": "Checkout",
    "MY_ADDRESS": "My Address",
    "BALANCE": "Balance",
    "PAID_AMOUNT": "Paid Amount",

    "PICK_ITEMS": "Pick Items",
    "PLEASE_SELECT_A_PAYMENT_METHOD": "Please select a payment method",
    "THERE_IS_NOTHING_IN_THE_CART_FOR_CHECKOUT": "There is nothing in the cart. Please add some products or packages to proceed.",
    "THERE_IS_NO_ADDRESS_REGISTERED_UNDER_THIS_ACCOUNT": "There is no address registered under this account. Please contact admin for more details.",
    "ADD_ADDRESS_SUCCESS": "Address Added",
    "PLEASE_UPLOAD_YOUR_PAYMENT_RECEIPT": "Please upload your payment receipt before proceeding",
    "PICK_1_ITEM_TO_CONTINUE": "Pick 1 item to continue",
    "PICK_AT_LEAST_1_ITEM_TO_CONTINUE": "Pick at least 1 item to continue",
    "PLEASE_SELECT_A_WALLET_FOR_PAYMENT": "Please select a wallet for payment",
    "PLEASE_ENTER_MOBILE_NUMBER": "Please enter your mobile number",
    "PLEASE_ENTER_CITY": "Please enter city",
    "SELECT_WAREHOUSE": "Select a warehouse",
    "PLEASE_UPLOAD_PAYMENT_RECEIPT": "Please upload your payment receipt before proceed",
    "PLEASE_ENTER_THE_SECURITY_PIN": "Please enter the security pin",
    "ENTER_VOUCHER_CODE": "Enter voucher code",
    "ORDER_HAS_BEEN_CREATED": "Your order has been created.",
    "PLEASE_WAIT_TRANSACTION_APPROVE": "Please wait for your transaction to be approved.",
    "PLEASE_PROCEED_TO_CHECKOUT_AGAIN": "Please proceed to checkout again",
    "YOUR_ORDER_PAYMENT_IS_UNSUCCESSFUL": "Your order payment is unsuccessful",
    "PLEASE_DO_NOT_ATTEMPT_PAYMENT_AGAIN": "Please do not attempt to make the same payment again unless the order status is changed to failed or you wish to make second purchase",
    "PLEASE_CONTACT_ADMIN": "Please contact admin or register again with other payment method",
    "YOUR_ORDER_PAYMENT_IS_PENDING": "Your order payment is pending and waiting for confirmation from bank",
    "PLEASE_WAIT_PATIENTLY": "Please wait patiently and receipt will be sent to your email if the payment is success",
    "PAYMENT_PENDING": "Payment Pending",
    "SALES_ORDER_NUMBER": "Sales Order Number",
    "RETURN_TO_DASHBOARD": "Return to Dashboard",
    "RETURN_TO_WALLET_RELOAD": "Return to Wallet Reload",
    "RETURN_TO_CHECKOUT": "Return to Checkout",
    "MANUAL_TRANSFER_OR_ATM": "Manual Transfer/ATM",
    "ONLINE_TRANSFER_OR_FPX": "Online Transfer/FPX",
    "CHECKOUT_CODE_IS_NEEDED": "Checkout code is needed for this item",

    "CHANGE": "Change",
    "ADD_NEW_ADDRESS": "Add New Address",
    "SET_AS_DEFAULT_ADDRESS": "Set As Default Address",
    "ONLINE_TRANSFER": "Online Transfer",
    "CARD": "Card",

    //Contact Us
    "EDIT_CONTACT_US": "Edit Contact Us",
    "CONTENT_IS_ADDED": "Content is added",
    "CONTENT_IS_UPDATED": "Content is updated",
    "DOWNLOAD_BA_APPLICATION_FORM": "Download BA Application Form",

    // Wallets
    "WALLET_TYPE": "Wallet Type",

    // Winna Dashboard
    "WELCOME_TO": "Welcome to our system new launching!",

    // Error Labels
    "EMAIL_IS_REGISTERED": "The email entered has been registered before. Please proceed to login or use another email for registration",
    "USERNAME_IS_REGISTERED": "The username entered has been used, please select a new username",
    "EMAIL_IS_INVALID": "The email provided is invalid. Please provide a valid email with correct email format",
    "REGISTRATION_LINK_INVALID": "There is a problem with the registration link",
    "REGISTER_AGE_RESTRICTION": "Registration only allow for user above 18 years old",
    "IC_REGISTERED_BEFORE": "IC number has been registered before.",
    "THERE_IS_NOT_ENOUGH_STOCK": "There is not enough stock",

    // Filter
    "SEARCH_BY_SALES_ORDER_NO": "Search By Sales Order No",
    "SEARCH_BY_SALES_INVOICE_NO": "Search By Sales Order Invoice No",
    "SEARCH_BY_MEMBER_ID": "Search By Member ID",
    "SEARCH_BY_MEMBER_FULL_NAME": "Search By Member Full Name",
    "SEARCH_BY_MEMBER_FIRST_NAME": "Search By Member First Name",
    "SEARCH_BY_MEMBER_LAST_NAME": "Search By Member Last Name",
    "SEARCH_BY_SALE_TYPES": "Search By Sale Types",
    "SEARCH_BY_PAYMENT_METHODS": "Search By Purchase Methods",
    "SEARCH_BY_ORDER_DATE": "Search By Order Date",
    "SEARCH_BY_TRANSACTION_APPROVAL_DATE": "Search By Transaction Approval Date",
    "SEARCH_APPROVED_BY": "Search Approved By",
    "SEARCH_BY_COLLECTION_METHODS": "Search By Collection Methods",
    "SEARCH_BY_SALES_STATUS": "Search By Sales Status",
    "SEARCH_BY_WAREHOUSE": "Search By Warehouse",

    "SEARCH_BY_MEMBER_USERNAME": "Search By Member Username",
    "SEARCH_BY_MEMBER_EMAIL": "Search By Member Email",
    "SEARCH_BY_MEMBER_MOBILE_NO": "Search By Member Mobile No",
    "SEARCH_BY_MEMBER_STATUS": "Search By Member Status",
    "SEARCH_BY_MEMBER_TIER": "Search By Member Tier",
    "SEARCH_BY_MEMBER_RANK": "Search By Member Rank",
    "SEARCH_BY_SPONSOR_ID": "Search By Sponsor ID",
    "SEARCH_BY_SPONSOR_NAME": "Search By Sponsor Name",
    "SEARCH_BY_PLACEMENT_ID": "Search By Placement ID",
    "SEARCH_BY_REGISTRATION_DATE": "Search By Registration Date",
    "SEARCH_BY_END_DATE": "Search By End Date",
    "SEARCH_BY_CATEGORY": "Search By Category",

    //Inventory
    "ADJUSTMENT_TYPE": "Adjustment Type",
    "CREATED_DATE": "Created Date",
    "UPDATED_DATE": "Updated Date",
    "FROM_PACKAGE": "From Package",
    "STOCK_BALANCE": "Stock Balance",
    "STOCK_SUCCESSFULLY_UPDATED": "Stock is successfully updated.",
    "AN_ERROR_OCCURED_WHILE_UPDATING_THE_STOCK": "An error occured while updating the stock.",
    "LOW_STOCK_ALERT_QUANTITY": "Low Stock Alert Quantity",
    "STOCK_QUANTITY": "Stock Quantity",
    "STOCK_ADJUSTMENT": "Stock Adjustment",
    "PLEASE_SPECIFY_THE_TYPE": "Please specify the type.",
    "PLEASE_SPECIFY_THE_ADJUSTMENT_TYPE": "Please specify the adjustment type.",
    "REPLENISHMENT": "Replenishment",
    "ADJUSTMENT": "Adjustment",
    "INCREASE": "Increase",
    "REDUCE": "Reduce",
    "STOCK_ALERT": "Stock Alert",
    "STOCK_CODE": "Stock Code",
    "SLUG": "Slug",
    "STOCK_NAME": "Stock Name",
    "REMAINING_QUANTITY": "Remaining Quantity",
    "WAREHOUSE": "Warehouse",
    "CHILD_WAREHOUSE": "Child Warehouse",
    "PARENT_WAREHOUSE": "Parent Warehouse",
    "CHILD_WAREHOUSE_ID": "Child Warehouse ID",
    "PARENT_WAREHOUSE_ID": "Parent Warehouse ID",
    "CHILD_WAREHOUSE_NAME": "Child Warehouse Name",
    "PARENT_WAREHOUSE_NAME": "Parent Warehouse Name",
    "WAREHOUSE_FOR_SELF_PICKUP": "Warehouse for Self Pick-Up",

    //KYC
    "PERSONAL_INFORMATION": "Personal Information",
    "SELECT_YOUR_BANK": "Select Your Bank",
    "SECURITY_PIN_ERROR": "Please enter 6-digit pin",
    "SUBMIT_SUCCESS": "Your submission has been sent",
    "PENDING_VERIFICATION_MESSAGE": "Thanks for completing the KYC Form. Please allowed the admin some times to process the request.",
    "VERIFICATION_FAILED_MESSAGE": "Admin had rejected your application, please contact your admin for more information.",
    "STATUS_UPDATE_SUCCESS": "Verification status is updated successfully.",
    "REUPLOAD_IC": "Re-upload IC",
    "CLOSE": "Close",
    "PLEASE_CHECK_ALL_FIELD_IS_FILLED_UP": "Please check on all mandatory field whether it is filled up",
    "PLEASE_UPLOAD_IC_FRONT": "Please upload IC Front photo",
    "FULL_NAME_AS_PER_IC": "Full name(as per IC) / Company Name",
    "FULL_NAME_OR_COMPANY_NAME": "Full name(as per IC) / Company Name",
    "REGISTRATION_TYPE": "Registration Type",
    "FRONT_IC_OR_PASSPORT": "Front IC/Passport",
    "SSM_REGISTRATION_CERT": "SSM Registration Cert",
    "SSM_REGISTRATION_CERT_WITH_IC": "SSM Registration Cert With IC",
    "PLEASE_UPLOAD_SSM_CERT": "Please Upload SSM Registration Cert",

    "ENTER_CURRENT_PASSWORD": "Enter Current Password",
    "ENTER_NEW_PASSWORD": "Enter New Password",
    "PLEASE_ENTER_NEW_PASSWORD": "Please enter your new password",
    "ENTER_CONFIRM_PASSWORD": "Enter Confirm Password",

    "ENTER_CURRENT_SECURITY_PIN": "Enter Current Security Pin",
    "ENTER_NEW_SECURITY_PIN": "Enter New Security Pin",
    "ENTER_CONFIRM_SECURITY_PIN": "Enter Confirm Security Pin",

    // Enum
    "IC": "IC",
    "PASSPORT": "Passport",
    "BUSINESS_REGISTRATION_NO": "Business Registration Number",

    //Login
    "ENTER_USERNAME": "Please input your username!",
    "ENTER_PASSWORD": "Please input your password!",
    "PASSWORD_HERE": "Password here",
    "PASSWORD": "Password",
    "VERIFICATION_CODE": "Verification Code",
    "LOGIN": "Login",
    "LOGIN_ID": "Login ID",
    "PICK_A_PRODUCT": "Pick A Product",
    "PICK_A_PACKAGE": "Pick A Package",
    "PICK_A_PRODUCT_PACKAGE": "Pick A Product/Package",
    "VERIFICATION_EMAIL_HAS_BEEN_SENT": "The verification email has been sent to email entered, please check your email",
    "PASSWORD_HAS_BEEN_RESET": "Your password has been reset. Please login with new password",
    "CAPTCHA": "Captcha",

    //Forget Password
    "FORGOT_PASSWORD": "Forgot Password?",
    "ENTER_EMAIL": "Enter your email and we'll send you a link to reset password.",
    "EMAIL_HERE": "Email here",
    "SEND_REQUEST": "Send Request",

    //ORDER
    "ORDER_DETAILS": "Order Details",

    "TIME": "Time",
    "PAYMENT_METHOD": "Payment Method",
    "COLLECTION_METHOD": "Collection Method",
    "SALES_ORDER_NO": "Sales Order No.",
    "ORDER_UNIQUE_ID": "Order Unique Id",
    "COLLECTION_TIME": "Collection Time",
    "UPDATED_BY": "Updated By",
    "AMOUNT_PAID": "Amount Paid",
    "INVOICE": "Invoice",
    "INVOICE_AMOUNT": "Invoice Amount",
    "DO": "DO",
    "SALES": "Sales",
    "SALES_TYPES": "Sales Types",
    "SALES_AMOUNT": "Sales Amount",
    "TOTAL_SALES_AMOUNT": "Total Sales Amount",
    "SALES_SV": "Sales SV",
    "TOTAL_SALES_SV": "Total Sales SV",
    "SALES_PV": "Sales PV",
    "TRANSACTION_APPROVAL_DATE": "Transaction Approval Date",
    "APPROVED_BY": "Approved By",

    "VIEW_SALES_ORDER": "View Sales Order",
    "VIEW_INVOICE": "View Invoice",
    "VIEW_DELIVERY_ORDER": "View Delivery Order",

    // Order Details Modal
    "ITEM": "ITEM",
    "ITEM_TYPE": "Item Type",
    "PACKAGE_DISTRIBUTOR": "Package (Distributor)",
    "VOUCHER_DISCOUNT": "Voucher Discount",

    "PAYMENT_SUCCESS": "Payment Success",
    "PAYMENT_FAILED": "Payment Failed",
    "PENDING": "Pending",
    "REJECTED": "Rejected",
    "PACKAGED": "Packaged",
    "SHIPPED": "Shipped",
    "DELIVERED": "Delivered",
    "CANCELED": "Canceled",
    "PROCESSING": "Processing",
    "PENDING_VERIFICATION": "Pending Verification",
    "REGISTRATION_FAILED": "Registration Failed",
    "VERIFICATION_FAILED": "Verification Failed",
    "SUSPENDED": "Suspended",
    "TERMINATED": "Terminated",
    "PENDING_REGISTRATION_UPGRADE": "Pending Registration Upgrade",
    "UFIT_TERMINATION": "UFIT Termination",
    "APPROVED": "Approved",

    "REGISTRATION_SALES": "Registration Sales",
    "SHOPPING_SALES": "Recurring Sales",
    "UPGRADE_SALES": "Upgrade Sales",
    "REDEMPTION_SALES": "Redemption Sales",

    //Package
    "CREATE_PACKAGE": "Create Package",
    "PACKAGE_NAME": "Package Name",
    "PACKAGE_DETAILS": "Package Details",
    "PACKAGE_SEQUENCE": "Package Sequence",
    "PACKAGE_DESCRIPTION": "Package Description",
    "PACKAGE_TYPE": "Package Type",
    "PACKAGE_CODE": "Package Code",
    "POINTS": "PV",
    "RETAIL_PRICE": "Retail Price",
    "FOREIGN_PRICE": "Foreign Price",
    "FOREIGN_PRICES": "Foreign Prices",
    "MEMBER_PRICE": "Member Price",
    "IS_SELL_AT_UPGRADE": "Is sell at upgrade",
    "IS_SELL_AT_PRIVILEGED": "Is sell at privileged",
    "IS_SELL_AT_REGISTRATION": "Is sell at registration",
    "IS_SELL_AT_REDEMPTION": "Is sell at redemption",
    "NEW_PACKAGE": "New Package",
    "PLEASE_ENTER_THE_NAME_OF_PACKAGE": "Please enter the name of package",
    "PLEASE_ENTER_THE_DESCRIPTION_OF_PACKAGE": "Please enter the description of package",
    "PLEASE_ENTER_THE_PRICE": "Please enter the price",
    "PLEASE_ENTER_THE_MEMBER_PRICE": "Please enter the member price",
    "PLEASE_ENTER_THE_FOREIGN_PRICE": "Please enter the foreign price",
    "PLEASE_ENTER_THE_POINTS": "Please enter the PV",
    "PACKAGE_IS_CREATED": "Package is created",
    "PACKAGE_IS_UPDATED": "Package is updated",
    "PACKAGE_IS_REMOVED": "Package is removed",
    "PACKAGE_IS_DUPLICATED": "Package is duplicated",
    "PACKAGE_SEQUENCE_IS_UPDATED": "Package sequence is updated",
    "AN_ERROR_OCCURED_WHILE_CREATING_THE_PACKAGE": "An error has occured while creating the package",
    "AN_ERROR_OCCURED_WHILE_UPDATING_THE_PACKAGE": "An error has occured while updating the package",
    "AN_ERROR_OCCURED_WHILE_REMOVING_THE_PACKAGE": "An error has occured while removing the package",
    "AN_ERROR_OCCURED_WHILE_UPDATING_THE_PACKAGE_SEQUENCE": "An error has occured while updating the package sequence",
    "ADD_REMOVE_PRODUCT": "Add/Remove Product",
    "PRODUCT_IS_ADDED": "Product is added",
    "PRODUCT_QUANTITY_IS_UPDATED": "Product quantity is updated",
    "PLEASE_SELECT_A_PRODUCT": "Please select a product",
    "PLEASE_ENSURE_ALL_COMBINATION_OF_PRODUCT_IS_VALID": "Please ensure all combination of product is valid",
    "PACKAGE_IMAGE_IS_ADDED": "Package image is added",
    "PACKAGE_IMAGE_IS_DELETED": "Package image is deleted",
    "IMAGE_UPLOAD_FAIL_MESSAGE": "Something went wrong during image upload. Please try again.",
    "IMAGE_DELETE_FAIL_MESSAGE": "Something went wrong during image deletion. Please try again.",
    "PACKAGE_IMAGE_DETAIL": "Package Image Detail",
    "PLEASE_ENTER_THE_QUANTITY": "Please enter the quantity",
    "PACKAGE_NOT_FOUND": "Package not found",
    "PLEASE_ENTER_THE_REBATE_AMOUNT": "Please enter the rebate amount",
    "PLEASE_SELECT_A_WALLET_TYPE": "Please select a wallet type",
    "PLEASE_SELECT_AMOUNT_TYPE": "Please select amount type",
    "REBATE_TYPE": "Rebate Type",
    "REBATE_AMOUNT": "Rebate Amount",
    "IS_SELL_AT_MALL": "Is Sell At Mall",
    "PLEASE_ENTER_THE_PACKAGE_CODE": "Please enter the package code",
    "PRODUCT_INCLUDED": "Product Included",
    "BASIC": "Basic",
    "PRO": "Pro",
    "REBATE": "Rebate",
    "AMOUNT_TYPE": "Amount Type",
    "TIER_CHANGE": "Tier Change",
    "ROLE_CHANGE": "Role Change",
    "ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_IMAGE": "Are you sure you want to delete this image?",
    "ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_PRODUCT": "Are you sure you want to remove this product?",

    //Product
    "CATEGORY_NAME": "Category Name",
    "PRODUCT_CATEGORY_IS_CREATED": "Product category is created",
    "PRODUCT_CATEGORY_IS_UPDATED": "Product category is updated",
    "PRODUCT_CATEGORY_IS_REMOVED": "Product category is removed",
    "AN_ERROR_OCCURED_WHILE_CREATING_THE_CATEGORY": "An error occured while creating the category",
    "AN_ERROR_OCCURED_WHILE_UPDATING_THE_CATEGORY": "An error occured while updating the category",
    "AN_ERROR_OCCURED_WHILE_REMOVING_THE_CATEGORY": "An error occured while removing the category",
    "NEW_PRODUCT_CATEGORY": "New Product Category",
    "EDIT_PRODUCT_CATEGORY": "Edit Product Category",
    "PLEASE_ENTER_NAME_FOR_THIS_CATEGORY": "Please enter a name for this category",
    "PLEASE_ENTER_DISPLAY_SEQUENCE_FOR_THIS_CATEGORY": "Please enter display sequence for this category.",
    "SMALLER_NUMBER_HIGHER_PRIORITY": "Smaller Number = Higher Priority",
    "PLEASE_ENTER_NAME_FOR_THE_NEW_CATEGORY": "Please enter a name for the new category",
    "ENTER_CATEGORY_NAME": "Enter category name",
    "CREATE_PRODUCT_CATEGORY": "Create Product Category",
    "ARE_YOU_SURE_YOU_WANT_TO_DELETE_PRODUCT_CATEGORY": "Are you sure you want to delete this product category?",
    "UPDATE_SEQUENCE": "Update Sequence",
    "CREATE_PRODUCT": "Create Product",
    "CREATE_GROUP": "Create Group",

    "TAG_NAME": "Tag Name",
    "PRODUCT_TAG_IS_CREATED": "Product tag is created",
    "PRODUCT_TAG_IS_UPDATED": "Product tag is updated",
    "PRODUCT_TAG_IS_REMOVED": "Product tag is removed",
    "AN_ERROR_OCCURED_WHILE_CREATING_THE_TAG": "An error occured while creating the tag",
    "AN_ERROR_OCCURED_WHILE_UPDATING_THE_TAG": "An error occured while updating the tag",
    "AN_ERROR_OCCURED_WHILE_REMOVING_THE_TAG": "An error occured while removing the tag",
    "NEW_PRODUCT_TAG": "New Product Tag",
    "EDIT_PRODUCT_TAG": "Edit Product Tag",
    "PLEASE_ENTER_NAME_FOR_THIS_TAG": "Please enter a name for this tag",
    "PLEASE_ENTER_NAME_FOR_THE_NEW_TAG": "Please enter a name for the new tag",
    "ENTER_TAG_NAME": "Enter tag name",
    "CREATE_PRODUCT_TAG": "Create Product Tag",
    "ARE_YOU_SURE_YOU_WANT_TO_DELETE_PRODUCT_TAG": "Are you sure you want to delete this product tag?",

    "NEW_PRODUCT": "New Product",
    "EDIT_PRODUCT": "Edit Product",
    "PRODUCT_NAME": "Product Name",
    "PRODUCT_CODE": "Product Code",
    "PRODUCT_CATEGORY": "Product Category",
    "PRODUCT_IS_CREATED": "Product is created",
    "PRODUCT_IS_UPDATED": "Product is updated",
    "PRODUCT_IS_REMOVED": "Product is removed",
    "PRODUCT_SEQUENCE_IS_UPDATED": "Product sequence is updated",
    "AN_ERROR_OCCURED_WHILE_CREATING_THE_PRODUCT": "An error occured while creating the product",
    "AN_ERROR_OCCURED_WHILE_UPDATING_THE_PRODUCT": "An error occured while updating the product",
    "AN_ERROR_OCCURED_WHILE_REMOVING_THE_PRODUCT": "An error occured while removing the product",
    "AN_ERROR_OCCURED_WHILE_UPDATING_THE_PRODUCT_SEQUENCE": "An error occured while updating the product sequence",
    "PLEASE_ENTER_NAME_FOR_THE_NEW_PRODUCT": "Please enter a name for the new product",
    "PLEASE_ENTER_THE_PRODUCT_CODE": "Please enter the product code",
    "PLEASE_ENTER_THE_WEIGHT_OF_THE_PRODUCT": "Please enter the weight of the product",
    "PLEASE_SELECT_A_CATEGORY": "Please select a category",
    "PLEASE_SELECT_A_TAG": "Please select a tag",
    "PLEASE_SPECIFY_THE_PRODUCT_STATUS": "Please specify the product status",
    "PLEASE_ADD_SOME_DESCRIPTION": "Please add some description",
    "GET_BULK_UPLOAD_TEMPLATE": "Get Bulk Upload Template",
    "ADD_VARIANT": "Add Variant",
    "GROUP_NAME": "Group Name",
    "SEARCH_BY_PRODUCT_CODE": "Search By Product Code",

    "CODE": "Code",
    "SOLD_AT_REGISTRATION": "Sold at registration?",
    "SOLD_AT_REDEMPTION": "Sold at redemption?",
    "SOLD_AT_UPGRADE": "Sold at upgrade?",
    "SOLD_AT_MALL": "Sold at mall?",
    "SOLD_AT_PRIVILEGE": "Sold at privilege?",
    "PRIVILEGE_ITEM": "Privilege Item",
    "BULK_UPLOAD_PRODUCTS": "Bulk Upload Products",
    "ALLOW_BACK_ORDER": "Allow Back Order",
    "GROUP_CODE": "Group Code",

    "VARIANT": "Variant",
    "VARIANTS": "Variants",
    "VARIANT_NAME": "Variant Name",
    "VARIANT_COMBINATION": "Variant Combination",
    "END_DATE": "End Date",
    "PROMOTION": "Promotion",
    "START_DATE": "Start Date",
    "VARIANT_GROUP": "Variant Group",
    "VARIANT_LEVEL_1": "Variant Level 1",
    "VARIANT_LEVEL_2": "Variant Level 2",
    "PRODUCT_DETAILS": "Product Details",
    "PRODUCT_DESCRIPTION": "Product Description",
    "PRODUCT_VARIANTS": "Product Variant",
    "PRODUCT_SEQUENCE": "Product Sequence",
    "PRODUCT_PROMOTION": "Product Promotion",
    "PLEASE_PICK_A_END_DATE": "Please pick a end date",
    "UPDATE_PRODUCT_VARIANTS": "Update Product Variants",
    "CREATE_PRODUCT_VARIANTS": "Create Product Variants",
    "CREATE_PRODUCT_PROMOTION": "Create Product Promotion",
    "UPDATE_PRODUCT_PROMOTION": "Update Product Promotion",
    "PLEASE_PICK_A_START_DATE": "Please pick a start date",
    "PRODUCT_VARIANT_IS_CREATED": "Product Variant is created",
    "PRODUCT_VARIANT_IS_UPDATED": "Product Variant is updated",
    "PRODUCT_VARIANT_IS_REMOVED": "Product Variant is removed",
    "PRODUCT_PROMOTION_IS_CREATED": "Product Promotion is created",
    "PRODUCT_PROMOTION_IS_UPDATED": "Product Promotion is updated",
    "PRODUCT_PROMOTION_IS_REMOVED": "Product Promotion is removed",
    "PLEASE_ENTER_THE_VARIANT_NAME": "Please enter the variant name",
    "PLEASE_SELECT_A_VARIANT_GROUP": "Please select a variant group",
    "CREATE_PRODUCT_VARIANT_COMBINATION": "Create Product Variant Combination",
    "UPDATE_PRODUCT_VARIANT_COMBINATION": "Update Product Variant Combination",
    "PRODUCT_VARIANT_COMBINATION_IS_CREATED": "Product Variant Combination is created",
    "PRODUCT_VARIANT_COMBINATION_IS_UPDATED": "Product Variant Combination is updated",
    "PRODUCT_VARIANT_COMBINATION_IS_REMOVED": "Product Variant Combination is removed",
    "PLEASE_ENTER_THE_COMBINATION_PRICE": "Please enter the price of this combination",
    "PLEASE_ENTER_THE_COMBINATION_FOREIGN_PRICE": "Please enter the foreign price of this combination",
    "PLEASE_ENTER_THE_COMBINATION_MEMBER_PRICE": "Please enter the member price of this combination",
    "PLEASE_ENTER_THE_COMBINATION_POINTS": "Please enter the PV of this combination",
    "PLEASE_ENTER_THE_STOCK_CODE": "Please enter the stock code",
    "PLEASE_ENTER_THE_SLUG": "Please enter the slug",
    "ADD_COMBINATION_PRICE": "Add Combination Price",
    "BY_PERCENTAGE": "By Percentage",
    "BY_VALUE": "By Value",
    "INDIVIDUAL_PV": "Individual PV",
    "TOTAL_PV": "Total PV",
    "TOTAL_POINT": "Total Point",
    "TARGET_ONLY": "Target Only",
    "PER_PRICE": "Per Price",
    "PER_PV": "Per PV",
    "PER_SKU": "Per SKU",
    "CREATE_DISCOUNT": "Create Discount",
    "PROMOTIONS": "Promotions",
    "DISCOUNTS": "Discounts",
    "DISCOUNT_LIST": "Discount List",
    "PERCENTAGE_DISCOUNT": "Percentage Discount",
    "AMOUNT_DISCOUNT": "Amount Discount",
    "EXACT_AMOUNT": "Exact Amount",
    "PWP_ITEM": "PWP Item",
    "CREATE_PWP": "Create PWP",
    "PROMO_CODE": "Promo Code",
    "CREATE_PROMO_CODE": "Create Promo Code",

    "PROMO_GROUPS": "Promo Groups",
    "PROMO_GROUP": "Promo Group",
    "PROMO_GROUPS_NAME": "Promo Groups Name",
    "PROMO_GROUP_NAME": "Promo Group Name",
    "PROMO_GROUP_IS_CREATED": "Promo group is created",
    "PROMO_GROUP_IS_UPDATED": "Promo group is updated",
    "PROMO_GROUP_IS_REMOVED": "Promo group is removed",
    "AN_ERROR_OCCURED_WHILE_CREATING_THE_PROMO_GROUP": "An error occured while creating the promo group",
    "AN_ERROR_OCCURED_WHILE_UPDATING_THE_PROMO_GROUP": "An error occured while updating the promo group",
    "AN_ERROR_OCCURED_WHILE_REMOVING_THE_PROMO_GROUP": "An error occured while removing the promo group",
    "NEW_PROMO_GROUP": "New Promo Group",
    "EDIT_PROMO_GROUP": "Edit Promo Group",
    "PLEASE_ENTER_NAME_FOR_THIS_PROMO_GROUP": "Please enter a name for this promo group",
    "PLEASE_ENTER_NAME_FOR_THE_NEW_PROMO_GROUP": "Please enter a name for the new promo group",
    "ENTER_PROMO_GROUP_NAME": "Enter promo group name",
    "CREATE_PROMO_GROUP": "Create Promo Group",
    "ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_PROMO_GROUP": "Are you sure you want to delete this promo group?",
    "MEMBERS": "Members",
    "ADD_MEMBERS": "Add Members",
    "IMPORT_MEMBERS": "Import Members",
    "MEMBER_IS_ADDED": "Member is added",
    "MEMBER_STATUS_IS_UPDATED": "Member status is updated",
    "MEMBER_IS_REMOVED": "Member is removed",
    "MEMBERS_ARE_IMPORTED": "Members are imported",
  
    "PLEASE_ENTER_THE_PROMOTION_FOREIGN_PRICE": "Please enter the foreign price of this promotion",
    "NOT_AVAILABLE": "Not Available",
    "OUT_OF_STOCK": "Out Of Stock",
    "PLEASE_ENTER_QUANTITY": "Please enter quantity",
    "PRODUCT_IMAGE_IS_CREATED": "Product Image is created",
    "PRODUCT_IMAGE_IS_REMOVED": "Product Image is removed",
    "CREATE_PRODUCT_IMAGE": "Create Product Image",
    "PRODUCT_IMAGE_DETAIL": "Product Image Detail",
    "PLEASE_ENTER_THE_FILENAME": "Please enter the filename",
    "FILE_NAME": "File Name",

    "WAREHOUSE_STOCK_DETAILS": "Warehouse Stock Details",
    "ADD_STOCK": "Add Stock",
    "PLEASE_SELECT_WAREHOUSE": "Please select warehouse",
    "QUANTITY_SHORTFORM": "Qty",
    "ENTER_QUANTITY": "Enter quantity",
    "ADD_STOCK_SUCCESS": "Stock is added",
    "ADD_STOCK_ERROR": "An error occured while adding the stock",
    "STOCK_EXIST": "Stock already exist",
    "UPDATE_STOCK": "Update stock",
    "UPDATE_STOCK_SUCCESS": "Stock is updated",
    "UPDATE_STOCK_ERROR": "An error occured while updating the stock",
    "DELETE_STOCK_SUCCESS": "Stock is removed",
    "DELETE_STOCK_ERROR": "An error occured while removing the stock",
    "ARE_YOU_SURE_DELETE_STOCK": "Are you sure you want to delete this warehouse stock",
    "NO_WAREHOUSE_FOUND": "No warehouse found",
    "PRODUCT_NOT_FOUND": "Product not found",
    "SEQUENCE_IS_UPDATED": "Sequence is updated",

    "SHORT_DESCRIPTION": "Short Description",
    "LONG_DESCRIPTION": "Long Description",
    "ENTER_SHORT_DESCRIPTION": "Enter a short description for the product",
    "ENTER_LONG_DESCRIPTION": "Enter long description for the product",
    "DATE_CREATED": "Date Created",

    //Registration
    "CUSTOMER_REGISTRATION": "Customer Registration",
    "BACK_TO_LOGIN_PAGE": "Back To Login Page",
    "REGISTER_NOW": "Register Now",

    "ORDER_SUMMARY": "Order Summary",
    "BILLING_DETAILS": "Billing Details",

    "CHOOSE_A_PACKAGE": "Choose A Package",
    "CHOOSE_A_PACKAGE_TYPE": "Choose a Package Type",
    "CONTINUE": "Continue",
    "BACK": "Back",

    "DONE": "Done",
    "CONGRATULATIONS": "CONGRATULATIONS",

    //Reports
    "BONUS_TYPE": "Bonus Type",
    "CALCULATION_DATE": "Calculation Date",
    "MEMBER_ID_OR_NAME_OR_IC": "Member ID/ Name/ IC",
    "FROM": "From",
    "INVOICE_ID": "Invoice ID",
    "INVOICE_NO": "Invoice No.",
    "POINTS_FROM_ORDER": "PV from Order",
    "TOTAL_COMMISSIONS": "Total Commissions",
    "HIDE_RECORDS_WITH_0_COMMISSION": "Hide records with 0 commission",

    "BONUS_SUMMARY": "Bonus Summary",
    "REWARD_TYPE": "Reward Type",
    "FROM_MEMBER_ID": "From (Member ID)",
    "FROM_MEMBER_NAME": "From (Member Name)",
    "FROM_SALES": "From Sales",
    "FROM_AMOUNT": "From Amount",
    "FROM_LEVEL": "From Level",
    "PERCENTAGE": "Percentage",

    "DAILY_PAIRED_HISTORY": "Daily Paired History",
    "BIG_ID": "Big (ID)",
    "BIG_NAME": "Big (Name)",
    "SUB_ID": "Sub (ID)",
    "SUB_NAME": "Sub (Name)",
    "BATCH_ID": "Batch ID",
    "PAIRED_SV": "Paired SV",

    "MONTHLY_PAIRED_HISTORY": "Monthly Paired History",
    "BONUS_RATE_1": "Bonus Rate Lv1",
    "BONUS_RATE_2": "Bonus Rate Lv2",
    "TOTAL_BONUS": "Total Bonus",
    "TOTAL_BONUS_EARNED": "Total Bonus Earned",
    "TOTAL_PAIRED_SV": "Total Paired SV",

    "PAIRING_DAILY_BALANCE": "Pairing Daily Balance",
    "DOWNLINE_ID": "Downline ID",
    "DOWNLINE_NAME": "Downline Name",
    "IS_BIG_LEG": "Is Big Leg",
    "SEQUENCE_NO": "Sequence No",
    "DAILY_INITIAL_SV": "Daily Initial SV",

    "SALES_SV_TO_MAINTENCE": "Sales SV To Maintenance",
    "TRANSACTION_DATE": "Transaction Date",
    "SALES_TYPE": "Sales Type",
    "ORIGINAL_SV": "Original SV",
    "MAINTAIN_SV": "Maintain SV",

    "UPGRADE_PACKAGE_RANK_HISTORY": "Upgrade Package Rank History",
    "FROM_RANK": "From Rank",
    "TO_RANK": "To Rank",
    "FROM_RANKING": "From Ranking",
    "TO_RANKING": "To Ranking",
    "UPGRADE_DATE": "Upgrade Date",

    "UPGRADE_RANK_HISTORY": "Upgrade Rank History",

    "SV_BALANCE": "SV Balance",
    "TOTAL_SV": "Total SV",

    "ALL_TIMES_SALES_SV": "All Time Sales SV",
    "TOTAL_PRICE": "Total Price",

    "TOTAL_SALES": "Total Sales",
    "TOTAL_DOWNLINE_SALES": "Total Downline Sales",

    "EXPORT_TO_CSV": "Export to csv",

    "SUM_MEMBER_REWARD_WALLET": "Sum Member Reward Wallet",
    "BONUS_EARNED": "Bonus Earned",

    "PLACEMENT_DOWN_PAIR_DETAILS": "Placement Down Pair Details",
    "BIG_LEG": "Big Leg",
    "SMALL_LEG": "Small Leg",
    "PAIR_SV": "Pair SV",
    "NON_SALES": "Non-Sales",
    "NON_SALES_REPORT": "Non-Sales Report",

    //Role Permission
    "ROLE_NAME": "Role Name",
    "ROLE_PERMISSION_IS_UPDATED": "Role permission is updated",
    "CUSTOM_PERMISSIONS": "Custom Permissions",

    "EDIT_ROLE_PERMISSION": "Edit Role Permission",
    "MODULE_NAME": "Module Name",

    "VIEW_PERMISSION": "View Permission",

    "EDIT_ROLE": "Edit Role",
    "CREATE_ROLE": "Add Role",

    "PLEASE_ENTER_THE_ROLE_NAME": "Please Enter The Role Name",

    "ROLE_IS_CREATED": "Role is created",
    "AN_ERROR_OCCURED_WHILE_CREATING_THE_ROLE": "An error occured while creating the role",

    //Shipment
    "DELIVERY": "Delivery",
    "SELF_PICKUP": "Self Pickup",

    "SHIPPING": "Shipping",
    "SHIPMENT_TYPE": "Shipment Type",
    "PURCHASER": "Purchaser",
    "PURCHASER_PHONE_NO": "Purchaser Phone No",
    "COURIER": "Courier",
    "SALES_DETAILS": "Sales Details",
    "SHIPPING_TO": "Shipping To",
    "SHIPPING_ADDRESS": "Shipping Address",
    "SHIPPING_DETAILS": "Shipping Details",
    "TRACKING_NUMBER": "Tracking Number",

    "VIEW_DETAILS": "View Details",
    "VIEW_RECEIPT": "View Receipt",
    "DISPENSE_PRODUCT": "Dispense Product",
    "COLLECTED": "Collected",
    "UPDATE_STATUS": "Update Status",
    "CHANGE_WAREHOUSE": "Change Warehouse",
    "PRINT_DO": "Print D.O.",
    "DELIVERY_STATUS": "Delivery status",
    "CONSIGNMENT_NOTE": "Consignment Note",
    "SHIPPING_METHOD": "Shipping Method",

    "SELECT_SHIPPING_METHOD": "Select Shipping Method",
    "SELECT_STATUS": "Select Status",
    "SELECT_COURIER": "Select Courier",
    "ENTER_CONSIGNMENT_NOTE": "Enter Consigment Note",
    "PLEASE_CONFIRM_THE_WAREHOUSE_ASSIGNMENT": "Please confirm the warehouse assignment for this order",
    "FROM_WAREHOUSE": "From Warehouse",
    "TO_WAREHOUSE": "To Warehouse",

    "UPDATE_ORDER_STATUS_SUCCESS": "Status updated",
    "UPDATE_ORDER_STATUS_ERROR": "An error occured while update status",
    "CHANGE_WAREHOUSE_SUCCESS": "Warehouse changed",
    "CHANGE_WAREHOUSE_ERROR": "An error occured while change warehouse",
    "GET_INVOICE_ERROR": "An error occured while generating invoice",
    "GET_DO_ERROR": "An error occured while generating DO",

    "DATE_FROM": "Date From",
    "DATE_TO": "Date To",
    "DO_ID": "Delivery Order Id",

    "delivery": "DELIVERY",
    "self-pickup": "SELF_PICKUP",

    //Shipment Zone
    "SHIPMENT_ZONE_AND_PRICING": "Shipment Zone & Pricing",
    "ZONE_PRICING": "Zone Pricing",
    "ADD_NEW_PRICE": "Add New Price",
    "ADD_NEW_SHIPPING_FEE": "Add New Shipping Fee",
    "NEW_ZONE_PRICE": "New Zone Price",
    "UPDATE_ZONE_PRICE": "Update Zone Price",
    "SHIPPING_FEES_BASED_ON_WEIGHT": "Shipping Fees Based on Weight",
    "SHIPPING_FEES_BASED_ON_ORDER_PRICE": "Shipping Fees Based on Order Price",
    "SHIPPING_FEES_MESSAGE_1": "NOTE: Shipping Fees based on Order Price will be prioritise.",
    "SHIPPING_FEES_MESSAGE_2": "If an order achieved both weight and order price criteria, shipping fees based on order price will be take into account.",
    "SHIPPING_ZONE_EXISTED_ERROR_MESSAGE": "There is already an shipping fees set for this amount of the same type for this zone",

    // labels
    "ZONE": "Zone",
    "TYPE": "Type",
    "WEIGHT": "Weight",
    "WEIGHT_IN_KG": "Weight In KG",
    "WEIGHT_IN_KG_SMALLER_OR_EQUAL_TO": "Weight In KG (smaller or equal to)",
    "SHIPPING_FEE": "Shipping Fee",
    "MINIMUM_PRICE_FOR_FREE_SHIPPING": "Minimum Price For Free Shipping",
    "ORDER_PRICE_MORE_THAN_OR_EQUAL_TO": "Order Price (more than or equal to)",
    "PRICE": "Price",
    "MYR": "MYR",


    // Form
    "ENTER_SECURITY_PIN": "Enter security pin",
    "PLEASE_ENTER_SECURITY_PIN": "Please enter curity pin",
    "ENTER_REMARKS": "Enter remarks",
    "PLEASE_ENTER_REMARKS": "Please enter remarks",
    "DEDUCT_FROM_WALLET_BALANCE": "Deduct From Wallet Balance?",
    "SELECT_ZONE": "Select Zone",
    "SELECT_TYPE": "Select Type",
    "ENTER_WEIGHT": "Enter weight in KG",
    "ENTER_PRICE": "Enter price",
    "ENTER_AMOUNT": "Enter Amount",
    "PLEASE_SELECT_ZONE": "Please Select Zone",
    "PLEASE_SELECT_TYPE": "Please Select Type",
    "PLEASE_ENTER_WEIGHT": "Please enter weight",
    "PLEASE_ENTER_SHIPPING_FEE": "Please enter shipping fee",
    "PLEASE_ENTER_AMOUNT": "Please enter amount",

    // action status
    "CREATE_SUCCESS": "Shipment zone price is created",
    "CREATE_ERROR": "An error occured while creating the shipment zone price",
    "UPDATE_SUCCESS": "Shipment zone price is updated",
    "UPDATE_ERROR": "An error occured while updating the shipment zone price",
    "DELETE_SUCCESS": "Shipment zone price is removed",
    "DELETE_ERROR": "An error occured while removing the shipment zone price",

    // alert message
    "ARE_YOU_SURE_DELETE_PRICE": "Are you sure you want to delete this price",

    //Sponsor Tree
    "ACCUMULATED": "Accumulated",
    "ACCUMULATED_PSV": "Accumulated PSV",
    "ACCUMULATED_PGSV": "Accumulated PGSV",
    "CURRENT_MONTH": "Current Month",
    "CURRENT_MONTH_PSV": "Current Month PSV",
    "CURRENT_MONTH_PGSV": "Current Month PGSV",
    "DOWNLINE_SALES_AMOUNT": "Downlines' Sales Amount",
    "LEVEL": "Level",
    "CURRENT_MONTH_SALES": "Current Month Sales",
    "DAILY_GROUP_SV": "Daily Group SV",
    "QUALIFIED_SV": "Qualified SV",
    "TOTAL_PAIRING_SV": "Total Pairing (SV)",
    "SPONSOR_LIST": "Sponsor List",
    "ENTER_NAME_ID_TO_SEARCH": "Enter name or ID to search",

    //Subadmin List
    "STAFF_REGISTRATION": "Staff Registration",
    "TERMINATE": "Terminate",
    "SUSPEND": "Suspend",
    "UNSUSPEND": "Unsuspend",
    "LOGIN_AS": "Login As",

    "ADMIN_ID": "Admin ID",
    "IDENTITY_TYPE": "Document Type",
    "LOGIN_PASSWORD": "Login Password",
    "COUNTRY_CODE": "Country Code",
    "NATIONALITY": "Nationality",

    "SUBADMIN_CREATED": "Subadmin is created",
    "SUBADMIN_UPDATED": "Subadmin is updated",

    //TNC
    "SCROLL_TO_AGREE_MESSAGE": "Please ensure to scroll and carefully read the entire agreement before clicking the \"Agree\" button.",
    "CONFIRM_ACCEPTANCE_MESSAGE": "By clicking the \"Agree\" button, you confirm your acceptance of the company's terms and conditions.",
    "AGREE": "Agree",
    "MEMBER_ACCEPTED_TNC_ON": "Member accepted the terms and conditions on",
    "TNC_ACCEPTANCE_DATE": "Terms and Conditions Acceptance Date",

    //User
    "USER": "User",
    "USER_MANAGEMENT": "User Management",
    "UFIT_GOLD": "Gold",
    "UFIT_PLATINUM": "Platinum",
    "UFIT_RUBY": "Ruby",
    "UFIT_DIAMOND": "Diamond",
    "UFIT_CROWN": "Crown",
    "IDENTIFICATION_NUMBER": "Identification Number",
    "RESIDENTIAL_ADDRESS_INFORMATION": "Resident Address Information",

    //Voucher
    "PRODUCT_SKU": "Product SKU",
    "PACKAGE_SKU_NAME": "Package SKU/Name",
    "GENERATION_DATE": "Generation Date",
    "CHECKOUT_CODE": "Checkout Code",
    "CHECKOUT_CODE_IS_CREATED": "Checkout code is created",
    "CHECKOUT_CODE_IS_UPDATED": "Checkout code is updated",
    "MAX_USAGE_PER_CODE": "Max usage per code",
    "MAX_USAGE_PER_PERSON": "Max usage per person",
    "REDEEMED_COUNT": "Redeem count",
    "INVOICE_DATE": "Invoice date",
    "DISCOUNT_TYPE": "Discount type",
    "DISCOUNT_AMOUNT": "Discount amount",
    "DISCOUNT_MAX_CAP": "Discount max cap",
    "VOUCHER": "Voucher",
    "VOUCHER_IS_CREATED": "Voucher is created",
    "VOUCHER_IS_UPDATED": "Voucher is updated",
    "VOUCHER_CODES": "Voucher Codes",
    "VOUCHER_CODE": "Voucher Code",
    "ADD_VOUCHERS": "Add Vouchers",
    "NO_MAXIMUM_CAP": "No Maximum Cap",
    "PUBLISH_DATE": "Publish Date",
    "USAGE_LIMIT_PER_CODE": "Usage Limit Per Code",
    "USAGE_LIMIT_PER_PERSON": "Usage Limit Per Person",
    "NO_USAGE_LIMIT": "No Usage Limit",
    "CONDITION": "Condition",
    "MINIMUM_PURCHASE": "Minimum Purchase",
    "MAXIMUM_CAP": "Maximum Cap",
    "ELIGIBILITY": "Eligibility",
    "VOUCHER_CODE_NEEDED": "Voucher Code Needed",
    "MEMBER_ONLY": "Member Only",
    "PUBLIC": "Public",
    "PROMOTION_TYPE": "Promotion type",
    "PROMOTION_AMOUNT": "Promotion amount",
    "PREORDER": "Preorder",

    //Wallet
    "WALLET": "Wallet",
    "WALLET_SETTINGS": "Wallet Settings",
    "EXPENSES": "Expenses",
    "EARNED": "Earned",

    // Table
    "FROM_ID": "From (ID)",
    "FROM_NAME": "From (Name)",
    "TO": "To",
    "TO_ID": "To (ID)",
    "TO_NAME": "To (Name)",
    "CREATED_BY": "Created By",
    "CREATED_BY_ID": "Created By (ID)",
    "CREATED_BY_NAME": "Created By (Name)",
    "SEARCH_BY_CREATED_BY": "Search By Created By",
    "HANDLED_BY": "Handled By",
    "SEARCH_BY_HANDLED_BY": "Search By Handled By",
    "FEE": "Fee",
    "FEE_TYPE": "Fee Type",
    "BENEFICIARY_NAME": "Beneficiary Name",
    "BENEFICIARY_ID_NO": "Beneficiary ID No.",
    "BENEFICIARY_ACCOUNT_NO": "Beneficiary Account No.",


    // Enum
    "PAYMENT": "Payment",
    "ORDER_REWARD": "Order Reward",
    "PENDING_BANK_CONFIRMATION": "Pending Bank Confirmation",
    "MANUAL_TRANSFER": "Manual Transfer",
    "PAYMENT_GATEWAY": "Payment Gateway",
    "PERCENT": "Percent",
    "FIXED": "Fixed",
    "ATM": "ATM",

    // Admin
    "SETTINGS": "Settings",
    "APPROVE": "Approve",
    "APPROVE_ALL": "Approve All",

    // Request message
    "PLEASE_SET_UP_SECURITY_PIN": "Please set up your security pin",
    "PLEASE_COMPLETE_KYC": "Please complete KYC before proceed",
    "UPDATE_SETTINGS_SUCCESS": "Settings updated",
    "UPDATE_SETTINGS_ERROR": "An error occured while update settings",
    "APPROVE_SUCCESS": "Approved",
    "REJECT_SUCCESS": "Rejected",
    "REVIEW_ERROR": "An error occured while review",

    //---Statement---
    "TRANSACTION_TYPE": "Transaction Type",

    //---Reload---
    "WALLET_RELOAD": "Wallet Reload",
    "RELOAD_TO": "Reload To",

    "ENTER_MEMBER_ID": "Enter Member ID",
    "PLEASE_ENTER_MEMBER_ID": "Please enter Member ID",
    "MEMBER_NAME": "Member Name",
    "SELECT_RELOAD_TO": "Select reload to",
    "PLEASE_SELECT_RELOAD_TO": "Please select reload to",
    "SELECT_PAYMENT_METHOD": "Select payment method",
    "PLEASE_SELECT_PAYMENT_METHOD": "Please select payment method",

    "NOT_REFUNDABLE": "Not refundable",
    "RELOAD_SUCCESS": "Wallet is reloaded",
    "RELOAD_SUCCESS_MESSAGE_1": "Your reload has been successfully done",
    "RELOAD_ERROR": "An error occured while reload the wallet",
    "RELOAD_FAILED": "Reload Failed",
    "RELOAD_FAILED_MESSAGE_1": "Your reload process is unsuccessful",
    "RELOAD_FAILED_MESSAGE_2": "Please proceed to reload again or contact admin for further assistance",
    "RELOAD_PENDING": "Reload Pending",
    "RELOAD_PENDING_MESSAGE_1": "Your reload process is pending and waiting for confirmation from bank",
    "RELOAD_PENDING_MESSAGE_2": "Please do not attempt to reload again unless the reload status is changed to failed or you wish to make second reload",

    //---Transfer---
    "WALLET_TRANSFER": "Wallet Transfer",

    "ENTER_FROM_MEMBER_ID": "Enter member ID",
    "PLEASE_ENTER_FROM_MEMBER_ID": "Please enter member ID",
    "TO_MEMBER_ID": "To (Member ID)",
    "ENTER_TO_MEMBER_ID": "Enter member ID",
    "PLEASE_ENTER_TO_MEMBER_ID": "Please enter member ID",
    "TO_MEMBER_NAME": "To (Member Name)",

    "MINIMUM_TRANSFER_AMOUNT": "Minimum Transfer Amount",
    "ENTER_MINIMUM_TRANSFER_AMOUNT": "Enter minimum transfer amount",
    "PLEASE_ENTER_MINIMUM_TRANSFER_AMOUNT": "Please enter minimum transfer amount",
    "TRANSFER_FEE": "Transfer Fee",
    "ENTER_TRANSFER_FEE": "Enter transfer fee",
    "PLEASE_ENTER_TRANSFER_FEE": "Please enter transfer fee",
    "TRANSFER_FEE_TYPE": "Transfer Fee Type",
    "SELECT_TRANSFER_FEE_TYPE": "Select transfer fee type",
    "PLEASE_SELECT_TRANSFER_FEE_TYPE": "Select transfer fee type",

    "TRANSFER_SUCCESS": "Amount is transferred",
    "TRANSFER_ERROR": "An error occured while transfer the wallet",
    "MEMBER_NOT_FOUND": "Member not found",

    //---Convert---
    "WALLET_CONVERT": "Wallet Convert",
    "FROM_WALLET": "From (Wallet)",
    "SELECT_FROM_WALLET": "Select wallet",
    "PLEASE_SELECT_FROM_WALLET": "Please select wallet",
    "TO_WALLET": "To (Wallet)",
    "SELECT_TO_WALLET": "Select wallet",
    "PLEASE_SELECT_TO_WALLET": "Please select wallet",
    "MINIMUM_CONVERT_AMOUNT": "Minimum Convert Amount",
    "ENTER_MINIMUM_CONVERT_AMOUNT": "Enter minimum convert amount",
    "CONVERT_FEE": "Convert Fee",
    "ENTER_CONVERT_FEE": "Enter convert fee",
    "CONVERT_FEE_TYPE": "Convert Fee Type",
    "SELECT_CONVERT_FEE_TYPE": "Select convert fee type",
    "PLEASE_SELECT_CONVERT_FEE_TYPE": "Select convert fee type",

    "CONVERT_SUCCESS": "Amount is converted",
    "CONVERT_ERROR": "An error occured while convert wallet",

    //---Withdrawal---
    "FINAL_AMOUNT": "Final Amount",

    "MINIMUM_WITHDRAWAL_AMOUNT": "Minimum Withdrawal Amount",
    "ENTER_MINIMUM_WITHDRAWAL_AMOUNT": "Enter minimum withdrawal amount",
    "WITHDRAWAL_FEE": "Withdrawal Fee",
    "ENTER_WITHDRAWAL_FEE": "Enter withdrawal fee",
    "WITHDRAWAL_FEE_TYPE": "Withdrawal Fee Type",
    "SELECT_WITHDRAWAL_FEE_TYPE": "Select withdrawal fee type",
    "PLEASE_SELECT_WITHDRAWAL_FEE_TYPE": "Select withdrawal fee type",
    "IS_DEDUCT_FROM_WITHDRAWAL_AMOUNT": "Is deducted from withdrawal amount",
    "SELECT_IS_DEDUCT_FROM_WITHDRAWAL_AMOUNT": "Select is deducted from withdrawal amount",
    "PLEASE_SELECT_IS_DEDUCT_FROM_WITHDRAWAL_AMOUNT": "Please select is deducted from withdrawal amount",

    "WALLET_WITHDRAWAL": "Wallet Withdrawal",
    "BANK": "Bank",
    "BANK_ACCOUNT": "Bank Account",
    "SELECT_BANK_ACCOUNT": "Select bank account",
    "PLEASE_SELECT_BANK_ACCOUNT": "Please select bank account",
    "WITHDRAWAL_CHARGE": "Withdrawal Charge",
    "BULK_PAYMENT_FORM": "Bulk Payment Form",

    "WALLET_BALANCE": "Wallet Balance",
    "BANK_CODE": "Bank Code",
    "BANK_HOLDER": "Bank Holder",

    "SELECT_WALLET_TYPE": "Select wallet type",
    "PLEASE_SELECT_WALLET_TYPE": "Pelase select wallet type",

    "WITHDRAWAL_SUCCESS": "Amount is transferred",
    "WITHDRAWAL_ERROR": "An error occured while withdrawal",

    //---Summary---
    "SUMMARY": "Summary",
    "EXPORT_WALLET_SUMMARY": "Export Wallet Summary",

    // button
    "CREATE_WAREHOUSE": "Create Warehouse",

    // modal title
    "NEW_WAREHOUSE": "New Warehouse",
    "UPDATE_WAREHOUSE": "Update Warehouse",

    // form label
    "ID": "ID",
    "DEFAULT_WAREHOUSE": "Default warehouse",

    // form placeholder
    "ENTER_ID": "Enter warehouse ID",
    "ENTER_NAME": "Enter warehouse name",
    "ENTER_ADDRESS": "Enter address",
    "ENTER_POSTCODE": "Enter postcode",
    "ENTER_CITY": "Enter City",
    "SELECT_COUNTRY": "Select country",
    "SELECT_STATE": "Select state",
    "SELECT_CITY": "Select city",
    "SELECT_REGION": "Select Region",

    // form validaition
    "PLEASE_ENTER_ID": "Please enter ID",
    "PLEASE_ENTER_NAME": "Please enter name",
    "PLEASE_ENTER_ADDRESS": "Please enter address",
    "PLEASE_ENTER_POSTCODE": "Please enter postcode",
    "PLEASE_SELECT_COUNTRY": "Please select country",
    "PLEASE_SELECT_STATE": "Please select state",
    "PLEASE_SELECT_CITY": "Please select city",
    "PLEASE_SELECT_REGION": "Please select region",

    // action status
    "CREATE_WAREHOUSE_SUCCESS": "Warehouse is created",
    "CREATE_WAREHOUSE_ERROR": "An error occured while creating the warehouse",
    "ID_EXIST": "Warehouse ID is exist",
    "NAME_EXIST": "Warehouse Name is exist",
    "UPDATE_WAREHOUSE_SUCCESS": "Warehouse is updated",
    "UPDATE_WAREHOUSE_ERROR": "An error occured while updating the warehouse",

    // region
    "MALAYSIA": "Malaysia",
    "WEST_MALAYSIA": "West Malaysia",
    "EAST_MALAYSIA": "East Malaysia",
    "SINGAPORE": "Singapore",
    "INDONESIA": "Indonesia",
    "PHILIPINES": "Philipines",
    "THAILAND": "Thailand",
    "BRUNEI": "Brunei",

    "VC_DELETE_MESSAGE": "This will also delete all images uploaded and stock record of this variant combination.",
    "PACKAGE_DELETE_MESSAGE": "This will also delete all images uploaded and products added into this package.",
    "PACKAGE_IS_DELETED": "Package is deleted",
    "AN_ERROR_OCCURED_WHILE_DELETING_THE_PACKAGE": "An error occured while deleting the package",
    "PRODUCT_IS_DELETED": "Product is deleted",
    "AN_ERROR_OCCURED_WHILE_DELETING_THE_PRODUCT": "An error occured while deleting the product",
    "PWP": "PWP",
    "GROUP_IMAGE": "Group Image",
    "SLUGS": "Slugs",
    "EXPORTED_TO_PC_CLUB": "Exported to PC Club",
    "TXN_ID": "Txn ID",
    "FPX": "FPX",
    "PREUPDATE_PRICE": "Preupdate Price",
    "PRICE_SAVED": "Price is saved",

    "VIEW_HISTORIES": "View Histories",
    "PURCHASE_LIMIT": "Purchase Limit",
    "ONCE": "Once",
    "EACH": "Each",
    "FREE_GIFT": "Free Gift",
    "PROMO_PRODUCTS": "Promo Products",
    "PROMO_BUNDLES": "Promo Bundles",
    "FOR_EACH": "For Each",
    "ONE_TIME": "One Time",

    "SEARCH_BY_EXPORTED": "Search by Exported",
    "PRIVILEGE_MALL_ACCESS": "Privilege Mall Access",
    "ENTITLE_TO_WELCOMING_VOUCHER": "Entitle to Welcoming Voucher",
    "IS_EO_ITEM": "Is EO Item",
    "UNIT": "Unit",
    "UNIQUE_ID": "Unique ID",
    "SHIPPING_SALES": "Shipping Sales",
    "SHIPPING_ORDER": "Shipping Order",
    "SHOPPING_ORDER": "Standard Order",
    "REDEMPTION_ORDER": "Redemption Order",
    "REGISTRATION_ORDER": "Registration Order",
    "TO_PACK": "To Pack",
    "TO_REPAY": "To Repay",
    "TO_RESHIP": "To Reship",
    "BULK_UPLOAD": "Bulk Upload",
    "ADJUSTED_BY": "Adjusted By",
    "NOT_FOUND": "Not Found",
    "WAREHOUSE_NOT_FOUND": "Warehouse Not Found",
    "LESS_THAN_ZERO": "Less Than Zero",
    "FAILED": "Failed",
    "BALANCE_QUANTITY": "Balance Quantity",
    "PICK_ONE_ONLY": "Pick One Only",
    "IS_SHIPPING_FEE": "Is shipping fee?",
    "IS_DANGEROUS_GOODS": "Is dangerous goods?",
    "REPAY_ORDER": "Repay Order",
    "TO_REPAY": "To Repay",
    "TO_RESHIP": "To Reship",
    "COMPLETE_RESHIP_ORDER": "Complete Reship Order",
    "BACK_ORDER_QUANTITY": "Back Order Quantity",
    "STOCK": "Stock",
    "NET_AMOUNT": "Net Amount",
    "SELL_IN_NON_SALES_TYPE": "Sell In Non Sales Type",
    "NON_SALES_TYPE": "Non Sales Type",
    "NON_SALES_ORDER": "Non Sales Order",
    "NON_SALES_SALES": "Non Sales Order",
    "BULK_UPDATE_NSO": "Bulk Update NSO",
    "SUCCESSFULLY_UPDATED": "Successfully Updated",
    "ACCESS": "Access",
    "SET_AS": "Set As",
    "READY_TO_COLLECT": "Ready To Collect",
    "PAYMENT_SERVICE": "Payment Service",
    "ASSIGN_CHILD_WAREHOUSES": "Assign Child Warehouses",
    "ASSIGN_CHILD_WAREHOUSE": "Assign Child Warehouse",
    "ASSIGN": "Assign",
    "UNASSIGN_CHILD_WAREHOUSE": "Unassign Child Warehouse",
    "UNASSIGN": "Unassign",
    "ACCESS": "Access",
    "SET_AS": "Set As",
    "READY_TO_COLLECT": "Ready To Collect",
    "GWP": "GWP",
    "GWP_ITEM": "GWP Item",
    "CREATE": "Create",
    "DISPLAY": "Display",
    "IS_USE_VOUCHER": "Is Use Voucher",
    "SOLD_AT_SPECIALS": "Sold at specials",
    "EXTRA_PV": "Extra PV",
    "DISCOUNT": "Discount",
    "BALANCE": "Balance",
    "STATEMENT": "Statement",
    "FROM_MEMBER": "From Member",
    "TO_MEMBER": "To Member",
    "COMMISSION_TYPE": "Commmission Type",
    "INVOICE_NUMBER": "Invoice Number",
    "BANK_NO": "Bank No",
    "ACCOUNT_HOLDER_NAME": "Account Holder Name",
    "MEMBER": "Member",
    "SEARCH_BY_DATE": "Search By Date",
    "REFERRAL": "Referral",
    "CREATE_REFERRAL_PROMOTION": "Create Referral Promotion",
    "PHASE": "Phase",
    "PERIODIC_TYPE": "Periodic Type",
    "DAILY": "Daily",
    "WEEKLY": "Weekly",
    "MONTHLY": "Monthly",
    "REGISTRATION": "Registration",
    "MANAGE_PHASE": "Manage Phase",
    "PHASE_NUMBER": "Phase Number",
    "PHASE_TYPE": "Phase Type",
    "ACCUMULATED_POINT": "Accumulated Point",
    "COMBINATIONS": "Combinations",
    "TARGET_USER": "Target User",
    "FOR_REFERRAL": "For Referral",
    "PERMANENT": "Permanent",
    "PICK_QUANTITY": "Pick Quantity",
    "CHANGE_OF_SPONSOR": "Change of Sponsor",
    "CURRENT": "Current",
    "CANCEL_ORDER": "Cancel Order",
    "APPROVAL_DATE": "Approval Date",
    "MAX_PURCHASE_LIMIT": "Maximum Purchase Limit",
    "IS_GWP_VOUCHER": "Is GWP Voucher",
    "VOUCHER_INFORMATION": "Voucher Information",
    "EXPIRY_PERIOD": "Expiry Period",
    "DAY": "Day",
    "WEEK": "Week",
    "MONTH": "Month",
    "PURCHASE_SUGGESTION": "Purchase Suggestion",
    "PURCHASE_SUGGESTIONS": "Purchase Suggestions",

    // Order Backdate
    "BACKDATE_DOCUMENT_NUMBER": "Backdate Document Number",
    "MIX": "Mix Payment"
  }
}
