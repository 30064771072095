import _ from "lodash";
import moment from "moment";
import { DownloadFile, Get, Post, Put } from "utils/axios";
import { convertObjectToBase64 } from "utils/objToBase64";
import { requestError } from "utils/requestHandler";

const { Component } = require("react");
const { connect } = require("react-redux");
const { addLoadingContent, removeLoadingContent } = require("reducers/LoadingOverlay");

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      query: {
        filter: {},
        sorter: {},
        page: 1
      },
      backdates: [],
      backdateMetadata: {},
      clientUserOrders: [],
      selectedCUOs: { keys: [], rows: [] },
      cuoOptionsColumns: [
        {
          title: "Invoice Number",
          filterItem: true,
          dataIndex: "invoice_number"
        },
        {
          title: "Member ID",
          filterItem: true,
          dataIndex: "client_user.username",
          render: (text, record) => record.client_user.username,
        },
        {
          title: "Member Name",
          filterItem: true,
          dataIndex: "client_user.full_name",
          render: (text, record) => record.client_user.full_name,
        },
        {
          title: "Invoice Date",
          filterItem: true,
          dataIndex: "created_at",
          render: (text, record) => moment(record.created_at).format("YYYY-MM-DD")
        },
        {
          title: "Amount (MYR)",
          dataIndex: "total_amount",
        },
        {
          title: "PV",
          dataIndex: "client_user_point_logs",
          render: (text, record) => _.sumBy(record.client_user_point_logs, 'amount') ?? 0,
        }
      ],
      uploadedDocument: [],
      uploadedDocumentInfo: {},
      selectedBackdate: {},
      action: null,
      showCUOOptionsModal: false,
    }

    load = param => {
      if( param ){
        this.props.addLoadingContent()
      } else {
        this.props.removeLoadingContent()
      }
    }

    onChangeHOC = (key, val) => this.setState({ [key]: val })

    getBackdates = () => Get(
      `/sites/${ this.props.siteInfo.id }/client_user_order_backdates?query=${ convertObjectToBase64({ ...this.state.query }) }`,
      (response) => this.setState({
        backdates: response.rows.map((backdate) => ({
          ...backdate,
          display_status: _.startCase(_.toLower(backdate.status.replace(/-/g, ' ')))
        })),
        backdateMetadata: response.meta
      }),
      requestError,
      this.load
    )

    getBackdateDetail = (id, action) => Get(
      `/sites/${ this.props.siteInfo.id }/client_user_order_backdates/${ id }`,
      (response) => {
        let data = response
        let selectedCUOs = { keys: data.client_user_order_backdates.map((item) => item.client_user_order_id), rows: data.client_user_order_backdates.map((item) => item.client_user_order) }
        let uploadedDocumentInfo = {
          file_name: data.document_url,
          base64: data.document_url,
          isExisting: true
        }

        this.setState({ selectedBackdate: data, selectedCUOs, uploadedDocumentInfo, action })
      },
      requestError,
      this.load
    )

    getCUOs = () => Get(
      `/sites/${ this.props.siteInfo.id }/orders?query=${ convertObjectToBase64({ filter: { created_at__between: [moment().startOf('month'), moment().date(4).endOf('day')], invoice_number__is_not: null, status__in: ["payment-success", "to-repay", "to-reship", "collected", "packaged", "delivered", "shipped"] }, sorter: {}, isDisabledPagination: true }) }`,
      (response) => this.setState({ clientUserOrders: response.rows }),
      requestError,
      this.load
    )

    exportToCSV = () => DownloadFile(
      `/sites/${ this.props.siteInfo.id }/client_user_order_backdates/exportCSV?query=${ convertObjectToBase64({ ...this.state.query }) }`,
      'Order Backdates.csv',
      requestError,
      this.load
    )

    approvalBackdate = (action) => {
      Put(
        `/sites/${ this.props.siteInfo.id }/client_user_order_backdates/${ this.state.selectedBackdate.id }/approval`,
        {action},
        (response) => {
          this.setState({ action: null, selectedBackdate: {} })
          this.getBackdates()
        },
        requestError,
        this.load
      )
    } 

    submitBackdate = (values) => {
      switch(this.state.action) {
        case 'create':
          Post(
            `/sites/${ this.props.siteInfo.id }/client_user_order_backdates`,
            values,
            (response) => {
              this.setState({ action: null, selectedBackdate: {} })
              this.getBackdates()
            },
            requestError,
            this.load
          )
          break
        case 'update':
          Put(
            `/sites/${ this.props.siteInfo.id }/client_user_order_backdates/${ this.state.selectedBackdate.id }`,
            values,
            (response) => {
              this.setState({ action: null, selectedBackdate: {} })
              this.getBackdates()
            },
            requestError,
            this.load
          )
          break
        default:
          return
      }
      
      
    }

    render = () => (
      <WrappedComponent
        {...this.props}
        {...this.state}
        onChangeHOC={this.onChangeHOC}
        getBackdates={this.getBackdates}
        getBackdateDetail={this.getBackdateDetail}
        getCUOs={this.getCUOs}
        exportToCSV={this.exportToCSV}
        submitBackdate={this.submitBackdate}
        approvalBackdate={this.approvalBackdate}
      />
    )
  }

  const mapStateToProps = (state) => ({
    data: state,
    colors: state.ClientReducer.clientInfo.styles?.colors,
    siteInfo: state.ClientReducer.siteInfo
  })

  return connect(mapStateToProps, { addLoadingContent, removeLoadingContent })(WithHOC)
}

export default HOC