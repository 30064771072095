import { isDisabled } from "@testing-library/user-event/dist/utils";
import _ from "lodash";
import moment from "moment";

export const DetailForm = {
  type: 'form',
  formRef: 'detailForm',
  onFinish: 'onSubmitDetailForm',
  children: [
    {
      type: 'div',
      className: 'row',
      children: [
        {
          type: 'div',
          className: 'col-12',
          children: [
            {
              type: 'input',
              label: '{{BACKDATE_DOCUMENT_NUMBER}}',
              name: 'document_no',
              inputConfig: {
                placeholder: '{{BACKDATE_DOCUMENT_NUMBER}}',
                size: 'large',
                isDisabled: 'disabledView'
              }
            }
          ]
        },
        {
          type: 'div',
          className: 'col-12 pb-3',
          children: [
            {
              type: 'text',
              content: "{{DOCUMENT}}"
            },
            {
              type: 'div',
              className: 'pb-3',
              isHide: 'hideView',
              children: [
                {
                  type: "file-pond",
                  files: "uploadedDocument",
                  acceptedFileTypes: [ "image/png", "image/jpeg", "image/jpg", "application/pdf" ],
                  allowMultiple: false,
                  returnFile: true,
                  actionKey: "UPLOAD_DOCUMENT"
                }
              ]
            },
            {
              type: 'div',
              className: 'd-flex py-3',
              isHide: "noDocumentUploaded",
              style: { 
                maxHeight: "66px", padding: '8px', 
                border: '1px solid #d9d9d9', borderRadius: '8px',          
                alignItems: 'center'
              },
              children: [
                {
                  type: "text", 
                  content: "<a target='_blank' href=\'${PLACEHOLDER}\'>${PLACEHOLDER}</a>", 
                  className: "link-opacity-100",
                  dataKey: "uploadedDocumentInfo.file_name",
                  style: {
                    overflow: 'hidden', whiteSpace: 'nowrap', 
                    textOverflow: 'ellipsis', padding: '0 8px',
                    flex: 'auto'
                  }
                }, 
                {
                  type: 'div',
                  children: [
                    {
                      type: 'button',
                      isHide: 'hideView',
                      buttonType: 'text', 
                      actionKey: 'REMOVE_UPLOADED_DOCUMENT',
                      children: [
                        {
                          type: "icon",
                          style: { fontSize: "15px", color: "$primary" },
                          antIcon: "DeleteOutlined"
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          type: 'div',
          className: 'col-12',
          children: [
            {
              type: 'form-item',
              name: 'client_user_orders',
              children: [
                {
                  type: 'table',
                  dataKey: 'selectedCUOs.rows',
                  actionsContent: [

                  ],
                  columnsContent: [
                    {
                      Header: '{{INVOICE_NUMBER}}',
                      accessor: 'invoice_number',
                      disableFilters: true,
                      disableSortBy: true
                    },
                    {
                      Header: '{{MEMBER_ID}}',
                      accessor: 'client_user.username',
                      disableFilters: true,
                      disableSortBy: true
                    },
                    {
                      Header: '{{MEMBER_NAME}}',
                      accessor: 'client_user.full_name',
                      disableFilters: true,
                      disableSortBy: true
                    },
                    {
                      Header: '{{INVOICE_DATE}}',
                      accessor: 'created_at',
                      valueType: 'dateTime',
                      dateTimeFormat: 'YYYY-MM-DD',
                      disableFilters: true,
                      disableSortBy: true
                    },
                    {
                      Header: '{{AMOUNT}}',
                      accessor: 'total_amount',
                      function: (val, original, props) => original.total_amount,
                      disableFilters: true,
                      disableSortBy: true
                    },
                    {
                      Header: '{{POINTS}}',
                      accessor: 'client_user_point_logs',
                      function: (val, original, props) => _.sumBy(original.client_user_point_logs, 'amount') ?? 0,
                      disableFilters: true,
                      disableSortBy: true
                    }
                  ]
                },
                {
                  type: "button",
                  size: "large",
                  className: "w-100",
                  isHide: "hideView",
                  actionKey: "OPEN_CUO_OPTIONS",
                  children: [
                    {
                      type: "text",
                      content: "{{SELECT}}"
                    }
                  ]
                }
              ]
            },
          ]
        },
        {
          type: 'div',
          className: 'col-12',
          isHide: 'finalized',
          children: [
            {
              type: 'text',
              className: 'text-danger',
              style: { fontSize: '28px' },
              content: `** All selected invoices will be backdated to: ${moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')}`,
            }
          ]
        },
      ]
    },
    { //section: submit button
      type: "div",
      className: "d-flex justify-content-end w-100",
      isHide: "finalized",
      children: [
        {
          type: "button",
          htmlType: "submit",
          buttonType: "text",
          isHide: "hideView",
          className: "custom-color my-3 ml-auto",
          style: { backgroundColor: "$quaternary", color: "#ffffff" },
          children: [
            {
              type: "text",
              isHide: "hideUpdate",
              content: "{{CREATE}}"
            },
            {
              type: "text",
              isHide: "hideCreate",
              content: "{{UPDATE}}"
            }
          ]
        },
        {
          type: "div",
          className: "d-flex",
          isHide: "hideCreate||hideUpdate",
          children: [
            {
              type: "button",
              htmlType: "button",
              buttonType: "text",
              className: "custom-color my-3 ml-2",
              style: { backgroundColor: "$quaternary", color: "#ffffff" },
              actionKey: "APPROVE_BACKDATE",
              children: [
                {
                  type: "text",
                  content: "{{APPROVE}}"
                }
              ]
            },
            {
              type: "button",
              htmlType: "button",
              buttonType: "text",
              className: "custom-color my-3 ml-2",
              style: { backgroundColor: "$quaternary", color: "#ffffff" },
              actionKey: "REJECT_BACKDATE",
              children: [
                {
                  type: "text",
                  content: "{{REJECT}}"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      type: "list-options",
      columns: "cuoOptionsColumns",
      dataSource: "clientUserOrders",
      onCancel: "CLOSE_CUO_OPTIONS",
      open: "showCUOOptionsModal",
      onSubmit: "ON_SUBMIT_CUO_OPTIONS",
      selectedKeys: "selectedCUOs"
    }
  ]
}