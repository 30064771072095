import { DetailForm } from "./detail";

const createFormModal = {
  type: 'modal',
  width: 1000,
  title: '{{CREATE}} {{ORDER_BACKDATE}}',
  openActionKey: 'isCreate',
  closeActionKey: 'CLOSE_DETAIL_MODAL',
  children: [
    DetailForm
  ]
};

const viewFormModal = {
  type: 'modal',
  width: 1000,
  title: '{{VIEW}} {{ORDER_BACKDATE}}',
  openActionKey: 'isView',
  closeActionKey: 'CLOSE_DETAIL_MODAL',
  children: [
    DetailForm
  ]
};

const updateFormModal = {
  type: 'modal',
  width: 1000,
  title: '{{UPDATE}} {{ORDER_BACKDATE}}',
  openActionKey: 'isUpdate',
  closeActionKey: 'CLOSE_DETAIL_MODAL',
  children: [
    DetailForm
  ]
};

export {
  createFormModal,
  viewFormModal,
  updateFormModal
};