import { createFormModal, viewFormModal, updateFormModal } from "./Form";
import { FilterForm } from "./Form/filter";

export const OrderBackdateLayout = {
  content: {
    type: 'div',
    children: [
      {
        //section: page title
        type: 'div',
        className: 'col-12',
        children: [
          {
            type: 'page-title',
            heading: '{{ORDER_BACKDATE}}',
            path: [
              {
                text: '{{ORDER_MANAGEMENT}}',
                disabled: true,
              },
              {
                text: '{{ORDER_BACKDATE}}',
                disabled: true,
              },
            ],
          },
        ],
      },
      { //subsection: export to file button
        type: 'div',
        className: 'w-100 d-flex justify-content-end mb-2 px-3',
        children: [
          {
            type: 'button',
            buttonType: 'text',
            className: 'custom-color',
            style: { backgroundColor: '$quaternary', color: '#ffffff' },
            actionKey: 'EXPORT_TO_CSV',
            children: [
              {
                type: 'icon',
                antIcon: 'ExportOutlined',
                style: {
                  marginRight: '12px'
                }
              },
              {
                type: "text",
                content: "{{EXPORT_TO_CSV}}"
              }
            ]
          }
        ]
      },
      {
        //section: content
        type: 'div',
        className: 'col-12',
        children: [
          {
            //section: card
            type: 'card',
            children: [
              {
                type: 'collapse',
                defaultActiveKey: [0],
                className: 'mb-4',
                panels: [
                  FilterForm
                ]
              },
              {
                //section: card action buttons
                type: 'div',
                className: 'd-flex justify-content-end w-100 mb-4',
                children: [
                  {
                    type: 'button',
                    isHide: 'noCreatePermission',
                    buttonType: 'text',
                    className: 'custom-color',
                    style: { backgroundColor: '$quaternary', color: '#ffffff' },
                    actionKey: 'OPEN_CREATE_MODAL',
                    size: 'large',
                    children: [
                      {
                        type: 'icon',
                        antIcon: 'PlusOutlined',
                        style: { color: '#fff', marginRight: '6px' },
                      },
                      {
                        type: 'text',
                        content: '{{CREATE}} {{ORDER_BACKDATE}}',
                      },
                    ],
                  }
                ]
              },
              {
                //section: table,
                type: 'table',
                dataKey: 'backdates',
                metadataKey: 'backdateMetadata',
                actionsContent: [
                  {
                    content: <i className="pe-7s-look btn-icon-wrapper" />,
                    actionID: 'ViewBackdate',
                    color: 'primary',
                    tooltipContent: "{{VIEW}}",
                    actionKey: "VIEW"
                  },
                  {
                    content: <i className="pe-7s-pen btn-icon-wrapper" />,
                    hide: (row, modulePermissions) => !modulePermissions.can_update || row.status !== 'pending-approval',
                    actionID: 'UpdateBackdate',
                    color: 'primary',
                    tooltipContent: "{{EDIT}}",
                    actionKey: "UPDATE"
                  }
                ],
                columnsContent: [
                  {
                    Header: '{{BACKDATE_DOCUMENT_NUMBER}}',
                    accessor: 'document_no',
                    disableFilters: true,
                    disableSortBy: true
                  },
                  {
                    Header: '{{CREATED_BY}}',
                    accessor: 'created_by_user.full_name',
                    disableFilters: true,
                    disableSortBy: true
                  },
                  {
                    Header: '{{STATUS}}',
                    accessor: 'display_status',
                    disableFilters: true,
                    disableSortBy: true
                  },
                  {
                    Header: '{{CREATED_DATE}}',
                    accessor: 'created_at',
                    valueType: 'dateTime',
                    dateTimeFormat: 'YYYY-MM-DD',
                    disableFilters: true,
                    disableSortBy: true
                  },
                  {
                    Header: '{{UPDATED_BY}}',
                    accessor: 'updated_by_user.full_name',
                    disableFilters: true,
                    disableSortBy: true
                  },
                  {
                    Header: '{{UPDATED_DATE}}',
                    accessor: 'updated_at',
                    valueType: 'dateTime',
                    dateTimeFormat: 'YYYY-MM-DD',
                    disableFilters: true,
                    disableSortBy: true
                  }
                ]
              }
            ],
          },
          createFormModal,
          updateFormModal,
          viewFormModal
        ]
      }
    ]
  }
}